import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import * as Feather from 'react-feather'
import { Link, useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { discountPrice, getCustomer, isEmptyObj } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Modal from "../add-ons/Modal"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const CustomerCategory = () => {
    const customer = getCustomer();
    const { id } = useParams()
    const [fetchLoading, setFetchLoading] = useState(true)
    const [productList, setProductList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [childCategoryList, setChildCategoryList] = useState([])
    const [parentCategory, setSelectedCategory] = useState('')
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(100000)
    const [showFiter, setShowFilter] = useState(false)
    const [brandList, setBrandList] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedBrandId, setSelectedBrandId] = useState(null);
    const [wishlistData, setWishlistData] = useState({})
    const navigate = useNavigate();
    const toast = useNotification()

    useEffect(() => {
        if (brandList.length > 0 && id) {
            const matchedBrand = brandList.find((brand) => String(brand.id) === String(id));
            if (matchedBrand) {
                setSelectedBrandId(matchedBrand.id);
            }
        }
    }, [brandList, id]);

    const handleBrandChange = (brandId) => {
        setSelectedBrandId(brandId);
        navigate(`/category/${brandId}`);
    };

    const getWishlist = () => {
        if (customer) {
            setFetchLoading(true)
            api.get('/wishlist/?customerId=' + customer.id, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    setWishlistData(response.data.data)
                }
            }).catch(error => {
                setWishlistData({})
            }).finally(() => setFetchLoading(false))
        }
    }

    const getCategoryProducts = useCallback(() => {
        setFetchLoading(true)
        api.get('/product/?categoryId=' + id + '&minPrice=' + minPrice + '&maxPrice=' + maxPrice + '&productStatus=active&shopStatus=active', customerReqConfig()).then(response => {
            if (response.status === 200) {
                setProductList(response.data.data)
            }
        }).catch(error => {
            setProductList([])
        }).finally(() => setFetchLoading(false))
    }, [id, maxPrice, minPrice])

    const getCategoryList = () => {
        setFetchLoading(true)
        api.get('/category/', customerReqConfig()).then(response => {
            if (response.status === 200) {
                setCategoryList(response.data.data.filter(c => c.name !== 'Home' && c.parentName !== 'Home'))
            }
        }).catch(error => {
            setCategoryList([])
        }).finally(() => setFetchLoading(false))
    }

    const getBrands = () => {
        setFetchLoading(true)
        api.get('/category/?parentId=-1', customerReqConfig()).then(response => {
            if (response.status === 200) {
                setBrandList(response.data.data)
            }
        }).catch(error => {
            setBrandList([])
        }).finally(() => setFetchLoading(false))
    }

    const handlePreviousCategoryList = () => {
        let parent = categoryList.filter(cl => String(parentCategory.parentId) === String(cl.id))
        let childList = categoryList.filter(cl => String(parentCategory.parentId) === String(cl.parentId))

        if (parent.length > 0) setSelectedCategory(parent[0])
        else setSelectedCategory('')
        setChildCategoryList(childList)
    }

    const handleMinPrice = (value) => setMinPrice(value)
    const handleMaxPrice = (value) => setMaxPrice(value)

    useEffect(() => {
        getCategoryProducts()
    }, [getCategoryProducts])

    useEffect(() => {
        getCategoryList()
        getBrands()
        getWishlist()
    }, [])

    useEffect(() => {
        if (categoryList.length > 0 && id !== '') {
            setSelectedCategory(categoryList.filter(cl => String(id) === String(cl.id))[0])
            setChildCategoryList(categoryList.filter(cl => String(id) === String(cl.parentId)))
        }
    }, [categoryList, id])

    return <div className="fixed-top-bar">
        <CustomerHeader enableButtons enableSearch />

        <div className="position-relative">
            {showFiter && <div className="position-absolute top-0 start-0">
                <Modal
                    component={<Filter
                        childCategoryList={childCategoryList}
                        brandList={brandList}
                        selectedBrands={selectedBrands}
                        handleBrandChange={handleBrandChange}
                        handleMaxPrice={handleMaxPrice}
                        handleMinPrice={handleMinPrice}
                        handlePreviousCategoryList={handlePreviousCategoryList}
                        id={id}
                        maxPrice={maxPrice}
                        minPrice={minPrice}
                        parentCategory={parentCategory}
                        selectedBrandId={selectedBrandId}
                    />}
                    handleClose={() => setShowFilter(false)}
                    headerText="Filter"
                    zIndex={1050}
                />
            </div>}
        </div>
        <div className="container">
            <div className="d-block d-lg-none text-end py-3 pe-4">
                <button className="btn btn-primary" onClick={e => setShowFilter(true)}> <i className="fas fa-filter" /> Filter</button>
            </div>
            <div className="row">
                <div className="d-none d-lg-block col-lg-3 py-2">
                    <Filter
                        childCategoryList={childCategoryList}
                        brandList={brandList}
                        selectedBrands={selectedBrands}
                        handleBrandChange={handleBrandChange}
                        handleMaxPrice={handleMaxPrice}
                        handleMinPrice={handleMinPrice}
                        handlePreviousCategoryList={handlePreviousCategoryList}
                        id={id}
                        maxPrice={maxPrice}
                        minPrice={minPrice}
                        parentCategory={parentCategory}
                        selectedBrandId={selectedBrandId}
                    />
                </div>
                <div className="col-lg-9">
                    <div className="bg-white">
                        {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> :
                            <div className="container">
                                <div className="row">
                                    {productList && productList.length > 0
                                        ?
                                        (selectedBrands.length > 0
                                            ? productList?.filter(f => selectedBrands.includes(f.brandId)).map(product => {
                                                return <Product product={product} wishlistData={wishlistData} toast={toast} key={nanoid()} getWishlist={getWishlist} customer={customer} />
                                            }) :
                                            productList.map(product => {
                                                return <Product product={product} wishlistData={wishlistData} toast={toast} key={nanoid()} getWishlist={getWishlist} customer={customer} />
                                            })
                                        )
                                        : <Error mainText="No products found" secondaryText={<Link to="/">Continue shopping</Link>} />}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const Product = ({ product, wishlistData, toast, getWishlist, customer }) => {
    const navigate = useNavigate();

    const IsProductWishlisted = wishlistData?.products?.find(f => String(f.id) === String(product.productId))

    const wishListHandler = () => {
        console.log('kk');

        if (!isEmptyObj(customer)) {

            if (!IsProductWishlisted) {

                const data = {
                    customerId: customer.id,
                    productId: product.productId
                }

                api.post('/wishlist/', data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        getWishlist()
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else {

                toast({ type: SUCCESS, message: 'Item already exists in wishlist' })

                // api.delete(`/wishlist/?customerId=${customer.id}&productId=${product.productId}`, customerReqConfig()).then(response => {
                //     if (response.status === 200) {
                //         getWishlist()
                //         toast({ type: SUCCESS, message: response.data.message })
                //         // setOrderPlaced(true)
                //     }
                // }).catch(error => {
                //     error.response ?
                //         toast({ type: ERROR, message: error.response.data.message }) :
                //         toast({ type: ERROR, message: error.message })
                // })
            }
        } else if (isEmptyObj(customer)) {
            console.log('kk2');
            toast({ type: ERROR, message: 'Please login to add product to wishlist' })

            navigate('/login')
        }
    }

    return <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4">

        <div className="text-center d-block p-3 border my-2 text-dark">
            <div className="text-end m-2">
                <i
                    className="fa fa-share-alt cursor-pointer"
                    onClick={() => {
                        const productLink = window.location.href;
                        navigator.clipboard.writeText(productLink);
                        alert('Product link copied to clipboard!');
                    }}
                    style={{ fontSize: '17px', color: '#555' }}
                ></i>
            </div>

            <Link to={"/product/" + product.productId} className=" p-3 my-2 text-dark">
                <img className="fit-contain hover-scale" style={{ height: '100px', width: '100px' }} src={product.mainImage && baseURL + '/' + product.mainImage} alt="" />
                <h6 className="my-2 text-truncate">{product.name}</h6>
                {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0 ?
                    <h6 className="fw-bold">&#8377;{Math.ceil(product.price)}</h6> :
                    <h6 className="fw-bold">
                        <span>&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                        <del className="text-muted mx-2">&#8377;{Math.ceil(product.price)}</del>
                        <span className="text-success">{product.discountLabel}</span>
                    </h6>}
            </Link>

            <div className="m-2 d-flex justify-content-between align-items-center">
                <div>
                    <i><Feather.Heart size={16} strokeWidth={1.6} /></i>
                    <Link to='#' className="ms-1 text-primary text-decoration-underline" onClick={wishListHandler}>
                        Add to Wishlist
                    </Link>
                </div>

            </div>
        </div>

    </div>
}

const Filter = ({ parentCategory, childCategoryList, handlePreviousCategoryList, minPrice, maxPrice, handleMinPrice, handleMaxPrice, id, brandList, handleBrandChange, selectedBrands, selectedBrandId }) => {

    return <div className="bg-white shadow-sm border">
        <h6 className="p-3 m-0">Filters</h6>
        <hr className="p-0 m-0" />
        <div className="p-3">
            <p className=" text-uppercase text-dark fw-bold">CATEGORIES</p>
            {parentCategory !== '' && <div to="#" className={`cursor-pointer small px-2 d-flex align-items-center text-dark ${String(id) === String(parentCategory.id) ? 'bg-light' : ''} fw-bold text-truncate`} onClick={() => handlePreviousCategoryList()}>
                <i className="fa fa-chevron-left me-2 small" />
                <span>{parentCategory.name}</span>
            </div>}
            {childCategoryList && childCategoryList.map((category, index) => {
                return <Link to={'/category/' + category.id} className={`ps-4 pe-2 text-truncate d-flex align-items-center d-block text-${String(id) === String(category.id) ? 'primary bg-light fw-bold' : 'muted'}`} key={nanoid()}>{category.name}</Link>
            })}
        </div>
        <hr className="p-0 m-0" />

        <div className="p-3">
            <p className=" text-uppercase text-dark fw-bold">BRANDS</p>

            {brandList && brandList.map((brand) => (
                <div key={brand.id} className="d-flex align-items-center">
                    <input
                        type="radio"
                        name="brand"
                        checked={selectedBrandId === brand.id}
                        onChange={() => handleBrandChange(brand.id)}
                    />
                    <label className={`ps-2 pe-2 text-truncate d-flex align-items-center text-${(selectedBrandId || id) === brand.id ? 'primary bg-light fw-bold' : 'muted'}`}>
                        {brand.name}
                    </label>
                </div>
            ))}
        </div>
        <hr className="p-0 m-0" />
        <div className="p-3">
            <p className=" text-uppercase text-dark fw-bold">Price</p>
            <div className="d-flex align-items-center justify-content-between">
                <div className="pe-2">
                    <label htmlFor="min" className="small text-dark">Min</label>
                    <input type="number" id="min" className="w-100 form-control form-control-sm" value={minPrice} onChange={e => handleMinPrice(e.target.value)} />
                </div>
                <div className="ps-2">
                    <label htmlFor="max" className="small text-dark">Max</label>
                    <input type="number" id="max" className="w-100 form-control form-control-sm" value={maxPrice} onChange={e => handleMaxPrice(e.target.value)} />
                </div>
            </div>
        </div>
        <hr className="p-0 m-0" />
    </div>
}

export default CustomerCategory