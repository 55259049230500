import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { api, superAdminReqConfig } from '../../include/api';
import { ERROR, SUCCESS } from '../../include/constant';
import { useNotification } from '../../Toast/ToastProvider';
import SuperAdminHeader from './Header';

const Wallet = () => {
    const toast = useNotification()
    const [wallets, setWallets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const getWallets = () => {
        api.get('/wallet/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setWallets(response.data.data)
            }
        }).catch(error => {
            setWallets([])
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        getWallets()
    }, []);

    // Open modal with selected wallet data
    const handleEdit = (wallet) => {
        setSelectedWallet({ ...wallet }); // Set selected wallet data in state
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedWallet((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {

        await api.put(`/wallet/?id=${selectedWallet.id}`, selectedWallet, superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message });
                    // setSelectedWallet(response.data.data)
                    setShowModal(false);
                    getWallets()
                }
            }).catch(error => {
                toast({ type: ERROR, message: error.response.data.message });

            })
    };

    return (
        <SuperAdminHeader >
            <div className="fixed-top-bar">
                <div className="container my-3">
                    <div style={{ padding: '20px' }}>
                        <h2>Wallet Records</h2>
                        {loading ? (
                            <p>Loading...</p>
                        ) : wallets?.length > 0 ? (
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>SI.No.</th>
                                            <th>Customer ID</th>
                                            <th>Order ID</th>
                                            <th>Wallet Amount</th>
                                            <th>Status</th>
                                            <th>Cancelled Date</th>
                                            <th>Settled Date</th>
                                            <th>Remarks</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {wallets?.map((wallet, i) => (
                                            <tr key={i + 1}>
                                                <td>{i + 1}</td>
                                                <td>{wallet.customerId}</td>
                                                <td>{wallet.orderId}</td>
                                                <td>{wallet.walletAmt}</td>
                                                <td>{wallet.status}</td>
                                                <td>{wallet.cancelledDate || '-'}</td>
                                                <td>{wallet.settledDate || '-'}</td>
                                                <td>{wallet.remarks || '-'}</td>
                                                <td>
                                                    <button className='btn btn-primary' onClick={() => handleEdit(wallet)}>Edit</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* Modal */}
                                {showModal && selectedWallet && (
                                    // <div
                                    //     style={{
                                    //         position: 'fixed',
                                    //         top: '50%',
                                    //         left: '50%',
                                    //         transform: 'translate(-50%, -50%)',
                                    //         backgroundColor: '#fff',
                                    //         padding: '20px',
                                    //         borderRadius: '8px',
                                    //         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    //         zIndex: 1000,
                                    //     }}
                                    // >
                                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Edit Wallet</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                    <label>Customer ID:</label>
                                                    <input
                                                        className='form-control  my-1'
                                                        readOnly
                                                        type="text"
                                                        name="customerId"
                                                        value={selectedWallet.customerId}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                    <label>Order ID:</label>
                                                    <input
                                                        className='form-control  my-1'
                                                        readOnly
                                                        type="text"
                                                        name="orderId"
                                                        value={selectedWallet.orderId}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                    <label>Wallet Amount:</label>
                                                    <input
                                                        className='form-control  my-1'
                                                        readOnly
                                                        type="number"
                                                        name="walletAmt"
                                                        value={selectedWallet.walletAmt}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                    <label>Status:</label>
                                                    <input
                                                        className='form-control  my-1'
                                                        readOnly
                                                        type="text"
                                                        name="status"
                                                        value={selectedWallet.status}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                    <label>Cancelled Date:</label>
                                                    <input
                                                        className='form-control  my-1'
                                                        readOnly
                                                        type="date"
                                                        name="cancelledDate"
                                                        value={(new Date(selectedWallet.cancelledDate)) || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                    <label>Settled Date:</label>
                                                    <input
                                                        className='form-control my-1'
                                                        type="date"
                                                        name="settledDate"
                                                        value={selectedWallet.settledDate || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='col-lg-12 col-md-12 col-sm-6'>
                                                    <label>Remarks:</label>
                                                    <input
                                                        className='form-control my-1'
                                                        type="textarea"
                                                        name="remarks"
                                                        value={selectedWallet.remarks || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='text-end my-2'>
                                                    <button className='btn btn-primary' onClick={handleSave} style={{ marginRight: '10px' }}>
                                                        Save
                                                    </button>
                                                    <button className='btn btn-primary' onClick={() => setShowModal(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                )}
                            </div>
                        ) : (
                            <p>No records found.</p>
                        )}
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default Wallet;