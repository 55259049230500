import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { api, baseURL, superAdminReqConfig } from "../../include/api";
import { ERROR, SUCCESS, statuses } from "../../include/constant";
import "../../styles/Super.css";
import { useNotification } from "../../Toast/ToastProvider";
import Flyout from "../common/Flyout";
import SuperAdminHeader from "./Header";
import FilterModal from "./FilterModal";

const Orders = ({ from }) => {
    const toast = useNotification()
    const [fetchLoading, setFetchLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [selectedOrderForCancel, setSelectedOrderForCancel] = useState(null);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [invObj, setInvObj] = useState(null);
    const [cusObj, setCusObj] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({ fromDate: "", toDate: "", orderId: "", product: "", paymentMethod: "", status: "" });
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [selectQuery, setSelectQuery] = useState("");
    const [product, setProduct] = useState([])
    const prevQueryRef = useRef();

    const handleSelectQueryChange = (query) => {
        setSelectQuery(query);
    };

    useEffect(() => {
        if (prevQueryRef.current !== selectQuery) {
            getProduct(selectQuery);
            prevQueryRef.current = selectQuery;
        }
    }, [selectQuery]);

    const getProduct = async (selectQuery) => {
        setFetchLoading(true);
        try {
            const response = await api.get(`/product/search/?q=${selectQuery}`);
            console.log(response.data);  // Now you can access the data directly
            if (response.status === 200 && Array.isArray(response.data.data.product)) {
                setProduct(response.data.data.product);
            }
        } catch (error) {
            console.error("Error fetching customer data:", error);
            setProduct([]); // In case of an error, set the customer array to empty
        } finally {
            setFetchLoading(false);
        }
    };


    const dynamicFilters = [
        { key: "orderId", label: "Order ID", type: "text" },
        {
            key: "product", label: "Product", type: "select", options: product.map((p) => ({
                value: p.id,
                label: p.name
            }))
        },
        {
            key: "paymentMethod", label: "Payment Method", type: "select", options: [
                { value: "cash on delivery", label: "cash on delivery" },
                { value: "online payment", label: "online payment" }
            ]
        },
        {
            key: "status", label: "Status", type: "select", options: statuses.map((s) => ({
                value: s,
                label: s
            }))
        },
    ];

    const getOrders = (filterValues = {}) => {
        setFetchLoading(true);

        const todayDate = new Date().toISOString().split("T")[0];
        const yesterdayDate = new Date(Date.now() - 86400000).toISOString().split("T")[0];

        const queryParams = new URLSearchParams(filterValues).toString();

        const url = queryParams
            ? `/order_for_super/?${queryParams}`
            : `/order_for_super/?fromDate=${yesterdayDate}&toDate=${todayDate}&orderId=&product=&paymentMethod=&status=`;

        api.get(url, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setOrderData(response.data.data);
                setFilteredData(response.data.data);
            }
        }).catch(error => {
            setOrderData([]);
            setFilteredData([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getOrders();
    }, []);

    const openModal = (orderId, customerID) => {
        setSelectedOrderId(orderId);
        setSelectedCustomerId(customerID);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedOrderId(null);
    };

    const confirmAddToInvoice = () => {
        console.log("Adding Order ID to Invoice: ", selectedOrderId);
        updateOrderPaymentStatus(selectedOrderId);
        closeModal();
        getOrders();
    };

    const handleApplyFilters = (appliedFilters) => {
        setFilters(appliedFilters);
        setIsModalOpen(false);
        getOrders(appliedFilters);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);
    
        const filtered = orderData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.name?.toLowerCase().includes(query?.toLowerCase()) ||
                data.paymentMode?.toLowerCase().includes(query?.toLowerCase()) ||
                data.status?.toLowerCase().includes(query?.toLowerCase()) ||
                data.orderItems.some((dataItem) => 
                    dataItem.productName?.toLowerCase().includes(query?.toLowerCase())
                )
            );
        });
    
        setFilteredData(filtered);
    };
    
    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Order Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Order Id', 'Order Date', 'Payment Method', 'Status', 'Total Amount', 'Address']],
            body: filteredData.map((od, i) => [
                i + 1,
                od.orderId,
                od.date,
                od.paymentMode,
                od.status,
                od.totalAmount,
                `${od.name}\nPhone no: ${od.phoneNumber}\n${od.address}\n${od.city}\n${od.state}`
            ])
        });
        doc.save('orders.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((od, i) => ({
            'S.No': i + 1,
            'Order Id': od.orderId,
            'Order Date': od.date,
            'Payment Method': od.paymentMode,
            'Status': od.status,
            'Total Amount': od.totalAmount,
            'Address': `${od.name},Phone no: ${od.phoneNumber},${od.address},${od.city},${od.state}`
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to handle opening the cancel modal
    const openCancelModal = (orderId, customerId) => {
        setSelectedOrderForCancel(orderId); // Store the order ID to be canceled
        setSelectedCustomerId(customerId);
        setShowCancelModal(true); // Show the modal
    };

    // Function to close the cancel modal
    const closeCancelModal = () => {
        setShowCancelModal(false);
        setSelectedOrderForCancel('')
        setSelectedCustomerId('')
        setCancelReason(""); // Clear the reason field
    };

    console.log(selectedCustomerId);
    const updateOrderPaymentStatus = (orderId) => {
        const customerId = selectedCustomerId
        api.put(`/order_for_super/?orderId=${orderId}`, { customerId }, superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    console.log("Order updated successfully:", response.data);
                    getOrders();
                }
            })
            .catch(error => {
                console.error("Error updating order:", error);
            });
    };

    // Function to submit the cancel order request with reason
    const submitCancelOrder = () => {
        if (cancelReason && selectedOrderForCancel) {
            const customerId = selectedCustomerId

            api.put(`/order_for_super/?orderId=${selectedOrderForCancel}&flag=cancelOrder`, { reason: cancelReason, customerId: customerId }, superAdminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        console.log("Order cancelled successfully:", response.data);
                        getOrders(); // Refresh the orders list after canceling
                    }
                })
                .catch(error => {
                    toast({ type: ERROR, message: error.response.data.message })
                    console.error("Error canceling order:", error);
                })
                .finally(() => {
                    closeCancelModal(); // Close the modal after submitting
                });
        }
    };

    const toggleFlyout = (invoice, from) => {
        setIsFlyoutVisible(!isFlyoutVisible)
        if (from === 'invoice') {
            setInvObj(invoice)
            setCusObj(null)
        }
        else if (from === 'customer') {
            setCusObj(invoice)
            setInvObj(null)
        }
        else {
            setInvObj(null)
            setCusObj(null)
        }
    };

    return (
        <>
            <Flyout isVisible={isFlyoutVisible} onClose={() => toggleFlyout({})} orderId={invObj?.orderId} customerId={cusObj?.customerId}>
            </Flyout>

            {/* Filter Modal */}
            <FilterModal
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onApply={handleApplyFilters}
                dynamicFilters={dynamicFilters}
                initialFilters={filters}
                selectQuery={selectQuery}
                setSelectQuery={handleSelectQueryChange}
            />

            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Orders</h5>
                        <div className="card-body">

                            {/* Search Filter */}
                            <div className="card my-4 row">
                                <div className="row ">
                                    <div className="search-section m-3 col-8">
                                        {/* Checkbox for toggling direct search */}
                                        <div className="form-check mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="directSearchCheckbox"
                                                checked={isCheckboxChecked}
                                                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                                            />
                                            <label className="form-check-label" htmlFor="directSearchCheckbox">
                                                Direct Search (Filter Table Data)
                                            </label>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by Order ID, Product Name, Payment Method or Status"
                                            value={searchQuery}
                                            onChange={isCheckboxChecked ? (e) => handleSearch(e.target.value) : undefined}
                                            onClick={!isCheckboxChecked ? () => setIsModalOpen(true) : undefined}
                                        />
                                    </div>
                                    <div className="col m-3 ">
                                        {from === 'reports' && (
                                            <>
                                                <button
                                                    className="btn btn-success mx-2"
                                                    onClick={downloadPDF}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <i className="fa-solid fa-download"></i> PDF
                                                </button>
                                                <button
                                                    className="btn btn-success mx-2"
                                                    onClick={downloadCSV}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <i className="fa-solid fa-download"></i> CSV
                                                </button>
                                            </>
                                        )}

                                    </div>
                                </div>
                                {/* Orders Table */}
                                <div className="table-wrapper">
                                    {fetchLoading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Product</th>
                                                    <th>Product Image</th>
                                                    <th>Order Id</th>
                                                    <th>Order Date</th>
                                                    <th>Payment Method</th>
                                                    <th>Status</th>
                                                    <th>Total Amount</th>
                                                    <th>Address</th>
                                                    {from === 'sales' && (<th>Action</th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                                                    filteredData.map((od, i) => (
                                                        <tr key={od.orderId}>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                {od.orderItems?.map((p, index) => (
                                                                    <span key={index}>{p.productName}{index < od.orderItems.length - 1 && ", "}</span>
                                                                ))}
                                                            </td>
                                                            <td>
                                                                {od.orderItems?.[0]?.mainImage ? (
                                                                    <img
                                                                        src={`${baseURL}/${od.orderItems[0].mainImage}`}
                                                                        alt="product"
                                                                        style={{ height: '60px', width: '50px' }}
                                                                    />
                                                                ) : (
                                                                    <span>No image available</span>
                                                                )}
                                                            </td>
                                                            <td className="cursor-pointer" onClick={() => toggleFlyout(od, 'invoice')}>{od.orderId}</td>
                                                            <td>{od.date}</td>
                                                            <td>{od.paymentMode}</td>
                                                            <td>{od.status}</td>
                                                            <td>{od.totalAmount}</td>
                                                            <td>
                                                                <b>{od.name}</b>
                                                                <br />
                                                                Phone no: {od.phoneNumber}
                                                                <br />
                                                                {od.address}
                                                                <br />
                                                                {od.city}
                                                                <br />
                                                                {od.state}
                                                            </td>
                                                            <td>
                                                                {from === 'sales' && (
                                                                    od.status !== 'cancelled' ? (
                                                                        <div className="d-flex flex-column">
                                                                            {od.status !== 'invoice' ? (
                                                                                <button
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => openModal(od.orderId, od.customerId)}
                                                                                >
                                                                                    Add to Invoice
                                                                                </button>
                                                                            ) : (
                                                                                <button className="btn btn-success">Added to Invoice</button>
                                                                            )}
                                                                            {od.status === 'ordered' ? (
                                                                                <button
                                                                                    className="btn btn-danger my-1"
                                                                                    onClick={() => openCancelModal(od.orderId, od.customerId)}
                                                                                >
                                                                                    Cancel Order
                                                                                </button>
                                                                            ) : (
                                                                                <button className="btn btn-secondary my-1" disabled>
                                                                                    Cannot Cancel
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <button className="btn btn-secondary my-1" disabled>
                                                                            Order Cancelled
                                                                        </button>
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="10" style={{ textAlign: 'center' }}>
                                                            No data available
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                {/* Cancel Order Modal */}
                                <Modal show={showCancelModal} onHide={closeCancelModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Cancel Order</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Please provide a reason for canceling this order.</p>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Reason for cancellation"
                                            value={cancelReason}
                                            onChange={(e) => setCancelReason(e.target.value)} // Update reason state
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={closeCancelModal}>
                                            Close
                                        </Button>
                                        <Button variant="danger" onClick={submitCancelOrder} disabled={!cancelReason}>
                                            Submit Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                {/* Modal */}
                                <Modal show={showModal} onHide={closeModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Payment</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>
                                            Do you want to add the order #{selectedOrderId} to the invoice?
                                        </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={closeModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={confirmAddToInvoice}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default Orders;
