import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { adminReqConfig, api, baseURL } from "../../include/api"
import { APPROVE, CANCELLED, CANCEL_DECLINE, CANCEL_REQUEST, DELIVERED, ORDERED, PACKED, RETURNED, RETURN_APPROVED, RETURN_REQUEST, RETURN_SHIPPED, SHIPPED, ERROR, SUCCESS } from "../../include/constant"
import { formateDate, getAdmin, preventClick, today } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import AdminHeader from "./Header"
import { useNotification } from "../../Toast/ToastProvider"
import Modal from "../add-ons/Modal"
import YesOrNo from "../add-ons/YesOrNo"


const AdminOrder = () => {
    const user = getAdmin()

    const [loading, setLoading] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [orders, setOrders] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [status, setStatus] = useState('')

    const handleFilter = () => setShowFilter(!showFilter)

    const getOrders = useCallback((afterGet) => {
        setLoading(true)
        api.get('/orders/?shopId=' + user.id + '&fromDate=' + fromDate + '&toDate=' + toDate + '&status=' + status, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setOrders(response.data.data)
                if (afterGet) afterGet()
            }
        }).catch(error => {
            setOrders([])
        }).finally(() => setLoading(false))
    }, [fromDate, status, toDate, user.id])


    useEffect(() => {
        if (user.id && user.id !== '' && fromDate !== '' && toDate !== '') {
            getOrders()
        }
    }, [fromDate, getOrders, toDate, user.id])

    useEffect(() => {
        setFromDate(today())
        setToDate(today())
    }, [])

    return <>
        <AdminHeader >
            <div className="fixed-top-bar">
                <div className="container my-1 mx-1">
                    <div className="row">
                        <div className="col">
                            <h5 className="mb-3">Orders</h5>
                        </div>
                        <div className="col text-end">
                            <button className="btn btn-primary d-md-none" onClick={e => preventClick(e, handleFilter)}>
                                <i className="fas fa-filter fa-fw me-2" />
                                <span>Filter</span>
                            </button>
                        </div>
                    </div>
                    <div className={`${!showFilter ? 'd-none' : ''} d-md-block`}>
                        <div className="row my-3">
                            <div className="col-12 col-md-4 mb-3">
                                <label htmlFor="fromDate">From Date</label>
                                <input type="date" className="form-control form-control-lg" name="fromDate" id="fromDate" value={fromDate} onChange={e => setFromDate(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label htmlFor="toDate">To Date</label>
                                <input type="date" className="form-control form-control-lg" name="toDate" id="toDate" value={toDate} onChange={e => setToDate(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label htmlFor="fromDate">Status</label>
                                <select name="status" className="form-control form-control-lg text-capitalize" id="status" onChange={e => setStatus(e.target.value)} value={status}>
                                    <option value="">All</option>
                                    <option value={ORDERED}>{ORDERED}</option>
                                    <option value={PACKED}>{PACKED}</option>
                                    <option value={SHIPPED}>{SHIPPED}</option>
                                    <option value={DELIVERED}>{DELIVERED}</option>
                                    <option value={RETURN_REQUEST}>{RETURN_REQUEST}</option>
                                    <option value={RETURN_APPROVED}>{RETURN_APPROVED}</option>
                                    <option value={RETURN_SHIPPED}>{RETURN_SHIPPED}</option>
                                    <option value={RETURNED}>{RETURNED}</option>
                                    <option value={CANCEL_REQUEST}>{CANCEL_REQUEST}</option>
                                    <option value={CANCELLED}>{CANCELLED}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {loading ? <div className="py-5"><Spinner color="danger" /></div> :
                        <div className="row">
                            {orders?.length > 0 ? (
                                orders?.map(order => (
                                    <OrderCard
                                        key={order.id}
                                        order={order}
                                        getOrders={getOrders}
                                        orderItems={order.orderItems}
                                        fromWhere="product"
                                    />
                                ))
                            ) : (
                                <Error mainText="No orders found!" />
                            )}
                        </div>}
                </div>
            </div>
        </AdminHeader>
    </>
}

const OrderCard = ({ order, orderItems, getOrders, fromWhere }) => {
    const toast = useNotification()
    const [showModal, setShowModal] = useState(false);
    const [modalComponent, setModalComponent] = useState(null);

    const [approveYesOrNo, setApproveYesOrNo] = useState(false)
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const [putLoading, setPutLoading] = useState(false)
    const [reason, setReason] = useState('')
    const [status, setStatus] = useState('')

    const handleApproveYes = () => approveOrDecline()

    const handleApprove = () => {
        setStatus(APPROVE)
        setApproveYesOrNo(true)
    }

    const handleDecline = () => {
        setStatus(CANCEL_DECLINE)
        setShowDeclineModal(true)
    }

    const setDefault = () => {
        setApproveYesOrNo(false)
        setShowDeclineModal(false)
        setReason('')
    }

    // const approveOrDecline = () => {
    //     console.log("Hello World!")
    //     const data = {
    //         status: String(status).trim(),
    //         adminComment: String(reason).trim()
    //     }

    //     setPutLoading(true)
    //     orderItems.map(item => 
    //     api.put('/orders/?id=' + item.id + '&flag=approveOrDecline', data, adminReqConfig()).then(response => {
    //         if (response.status === 200) {
    //             getOrders(() => {
    //                 setDefault()
    //                 toast({ type: SUCCESS, message: response.data.message })
    //             })
    //         }
    //     }).catch(error => {
    //         error.response ?
    //             toast({ type: ERROR, message: error.response.data.message }) :
    //             toast({ type: ERROR, message: error.message })
    //     }).finally(() => setPutLoading(false))
    //     )
    // }

    const approveOrDecline = () => {
        console.log("Decline Process Initiated!");
        const data = {
            status: String(status).trim(),
            adminComment: String(reason).trim(),
        };

        setPutLoading(true);

        // Make the API request for each order item
        orderItems.map(item =>
            api.put('/orders/?id=' + item.id + '&flag=approveOrDecline', data, adminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        getOrders(() => {
                            setDefault();
                            toast({ type: SUCCESS, message: response.data.message });
                        });
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message });
                }).finally(() => setPutLoading(false))
        );
    };


    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <h6>Orders Info</h6>
                        <p className="mb-0 text-muted">Placed on {formateDate(order.createTimestamp)}</p>
                    </div>
                    {order.orderItems.some(item => item.status === CANCEL_REQUEST) && <><button className="btn btn-success me-3 text-light" onClick={e => preventClick(e, handleApprove)}>Approve</button>
                        <button className="btn btn-danger me-3 text-light" onClick={e => preventClick(e, handleDecline)}>Decline</button></>}
                </div>
                <div className="card-body">
                    {order.orderItems.map(item => (
                        <OrderItem key={item.id} item={item} fromWhere={fromWhere} />
                    ))}
                </div>
                <div className="card-footer">
                    <strong>Total Price: &#8377;{order.totalAmount}</strong>
                </div>
            </div>

            {approveYesOrNo && <YesOrNo
                zIndex={1040}
                handleYes={handleApproveYes}
                handleNo={() => setApproveYesOrNo(false)}
                headerText="Are you sure want to Approve?"
                yesBtnText="Yes"
                loading={putLoading}
            />}
            {showDeclineModal && (
                <Modal
                    zIndex={1040}
                    component={
                        <Decline
                            approveOrDecline={approveOrDecline}
                            reason={reason}
                            setReason={setReason}
                            putLoading={putLoading}
                        />
                    }
                    handleClose={() => setShowDeclineModal(false)}
                    headerText="Are you sure want to Decline?"
                />
            )}
        </div>
    );
};

const Decline = ({ approveOrDecline, reason, putLoading, setReason }) => {
    return (
        <div className="w-100">
            <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
            <textarea
                name="reason"
                id="reason"
                cols="30"
                rows="5"
                className="form-control mb-3"
                placeholder="Reason"
                style={{ resize: 'none' }}
                onChange={(e) => setReason(e.target.value)}
                value={reason}
            />
            {(putLoading || String(reason).trim() === '') ? (
                <button className="btn" style={{ cursor: "no-drop" }} onClick={(e) => e.preventDefault()}>Decline</button>
            ) : (
                <button
                    className="btn btn-danger text-end"
                    onClick={(e) => preventClick(e, approveOrDecline)}
                >
                    Decline
                </button>
            )}
        </div>
    );
};



const OrderItem = ({ item, fromWhere }) => {
    return (
        <Link to={"/admin/order-details/" + item.id} className="d-block p-2 border-bottom">
            <div className="d-flex align-items-center">
                <img
                    className="me-3"
                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                    src={item.mainImage && baseURL + '/' + item.mainImage}
                    alt={item.productName}
                />
                <div className="flex-grow-1" style={{ overflow: 'hidden' }}>
                    <h6
                        className="mb-1 text-truncate"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {item.productName}
                    </h6>
                    <span className="text-muted small">
                        &#8377;{fromWhere === 'service'
                            ? Math.ceil(item.amount)
                            : Math.ceil(item.amount + (item.deliveryCharge))
                        }

                    </span>
                </div>
                <div className="text-end">
                    <span className={`badge  bg-success`}>
                        {item.status}
                    </span>
                </div>
            </div>
        </Link>
    )
}


export default AdminOrder