import React, { useState } from "react";
import Select from "react-select";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const FilterModal = ({ show, onClose, onApply, dynamicFilters, selectQuery, setSelectQuery }) => {
    const todayDate = new Date().toISOString().split("T")[0];
    const yesterdayDate = new Date(Date.now() - 86400000).toISOString().split("T")[0];

    const [filters, setFilters] = useState({
        fromDate: yesterdayDate,
        toDate: todayDate,
        ...dynamicFilters.reduce((acc, filter) => ({ ...acc, [filter.key]: "" }), {}),
    });

    const handleChange = (key, value) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const handleApply = () => {
        onApply(filters);
    };

    const clearHandler = () => {
        setFilters({
            fromDate: yesterdayDate,
            toDate: todayDate,
            ...dynamicFilters.reduce((acc, filter) => ({ ...acc, [filter.key]: "" }), {})
        })
    }

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Date Filters in Row */}
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>From Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={filters.fromDate}
                                    onChange={(e) => handleChange("fromDate", e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>To Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={filters.toDate}
                                    onChange={(e) => handleChange("toDate", e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Dynamic Filters */}
                    {dynamicFilters.map((filter) => (
                        <Form.Group key={filter.key}>
                            <Form.Label>{filter.label}:</Form.Label>
                            {filter.type === "select" ? (
                                <Select
                                    isSearchable
                                    placeholder={`Select ${filter.label}`}
                                    options={filter.options}
                                    value={
                                        filter.options.find(
                                            (option) => option.value === filters[filter.key]
                                        ) || null
                                    }
                                    onChange={(selectedOption) =>
                                        handleChange(filter.key, selectedOption?.value || "")
                                    }
                                    onInputChange={(inputValue) => {
                                        setSelectQuery(inputValue); 
                                    }}
                                />
                            ) : (
                                <Form.Control
                                    type="text"
                                    placeholder={`Enter ${filter.label}`}
                                    value={filters[filter.key]}
                                    onChange={(e) => handleChange(filter.key, e.target.value)}
                                />
                            )}
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={clearHandler}>
                    Clear
                </Button>
                <Button variant="primary" onClick={handleApply}>
                    Apply Filters
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FilterModal;

