import React, { Suspense, useState } from "react";
import { BarChart2, CreditCard, DollarSign, FileText, Home, Image, List, LogOut, Map, Settings, ShoppingBag, User, UserCheck } from 'react-feather';
import '../../styles/Admin.css';
import AdminFooter from "../common/AdminFooter";
import AdminTopNavBar from "../common/AdminTopNavBar";
import SuperSideBar from "./SuperSideBar";

const SuperAdminHeader = ({ children }) => {
    const homeLink = '/super'
    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

    const toggleSidebar = () => setIsSidebarMinimized(!isSidebarMinimized);
    const menuContent = [
        {
            key: "dashboard",
            url: "/super/dashboard",
            isTitle: false,
            text: "Dashboard",
            Icon: BarChart2,
            // children: [
            //     {
            //         key: "sales dashboard",
            //         url: "/super/sales_dashboard",
            //         isTitle: false,
            //         text: "Sales",
            //         Icon: ShoppingBag,
            //         parentKey: 'dashboard'
            //     },
            //     {
            //         key: "orders dashboard",
            //         url: "/super/orders_dashboard",
            //         isTitle: false,
            //         text: "Orders",
            //         Icon: ShoppingBag,
            //         parentKey: 'dashboard'
            //     },
            //     {
            //         key: "Report",
            //         url: "/super/report",
            //         isTitle: false,
            //         text: "Report",
            //         Icon: ShoppingBag,
            //         parentKey: 'dashboard'
            //     },
            // ]

        },
        {
            key: "shop",
            url: "/super/shop",
            isTitle: false,
            text: "Shop",
            Icon: ShoppingBag,
        },
        {
            key: "service_orders",
            url: "/super/service_orders",
            isTitle: false,
            text: "Service Orders",
            Icon: UserCheck,
        },
        // {
        //     Key: "customer",
        //     url: "/super/customer",
        //     istitle: false,
        //     text: "Customer",
        //     Icon: User,
        // },
        {
            key: "sales",
            // url: "/super/dashboard",
            isTitle: false,
            text: "Sales",
            Icon: DollarSign,
            children: [
                {
                    key: "orders",
                    url: "/super/sales/orders",
                    istitle: false,
                    text: "Orders",
                    Icon: List,
                    parentKey: 'sales'
                },
                {
                    key: "invoice",
                    url: "/super/sales/invoice",
                    istitle: false,
                    text: "Invoice",
                    Icon: List,
                    parentKey: 'sales'
                },
                {
                    key: "payments",
                    url: "/super/sales/payments",
                    istitle: false,
                    text: "Payments",
                    Icon: List,
                    parentKey: 'sales'
                },
                {
                    key: "shipping",
                    url: "/super/sales/shipping",
                    istitle: false,
                    text: "Shipping",
                    Icon: List,
                    parentKey: 'sales'
                },
                {
                    key: "Tracking",
                    url: "/super/sales/Tracking",
                    istitle: false,
                    text: "Tracking",
                    Icon: List,
                    parentKey: 'sales'
                },
            ]
        },
        {
            key: "catalogue",
            // url: "/super/dashboard",
            isTitle: false,
            text: "Catalogue",
            Icon: Map,
            children: [
                {
                    key: "category",
                    url: "/super/category",
                    istitle: false,
                    text: "Categories",
                    Icon: List,
                    parentKey: 'catalogue'
                },
                {
                    key: "service_category",
                    url: "/super/service_category",
                    istitle: false,
                    text: "Service Categories",
                    Icon: List,
                    parentKey: 'catalogue'
                },
                {
                    key: "brand",
                    url: "/super/brand",
                    istitle: false,
                    text: "Brands",
                    Icon: List,
                    parentKey: 'catalogue'
                },
                {
                    key: "products",
                    url: "/super/products",
                    istitle: false,
                    text: "Products",
                    Icon: List,
                    parentKey: 'catalogue'
                },
                {
                    key: "service_or_manpower",
                    url: "/super/service_or_manpower",
                    istitle: false,
                    text: "Service/Man Power",
                    Icon: List,
                    parentKey: 'catalogue'
                },
            ]
        },
        {
            key: "voucher",
            url: "/super/voucher",
            istitle: false,
            text: "Voucher",
            Icon: CreditCard,
        },
        {
            key: "content",
            // url: "/super/dashboard",
            isTitle: false,
            text: "Content",
            Icon: Image,
            children: [
                {
                    key: "slide",
                    url: "/super/slide",
                    istitle: false,
                    text: "Slide",
                    Icon: Image,
                    parentKey: 'content'
                },
                {
                    key: "pages",
                    url: "/super/pages",
                    istitle: false,
                    text: "Pages",
                    Icon: Image,
                    parentKey: 'content'
                },
            ]
        },
        {
            key: "home",
            url: "/super/home_page",
            istitle: false,
            text: "Home Page",
            Icon: Home,
        },
        {
            key: "customers",
            // url: "/super/dashboard",
            isTitle: false,
            text: "Customer",
            Icon: User,
            children: [
                {
                    Key: "customer",
                    url: "/super/customer",
                    istitle: false,
                    text: "Customer",
                    Icon: User,
                    parentKey: 'customers'
                },
                // {
                //     Key: "customer_type_permissions",
                //     url: "/super/customer_type_permissions",
                //     istitle: false,
                //     text: "Customer Type Permissions",
                //     Icon: User,
                //     parentKey: 'customers'
                // },
                // {
                //     Key: "customer_permission",
                //     url: "/super/customer_permission",
                //     istitle: false,
                //     text: "Customer Permission",
                //     Icon: User,
                //     parentKey: 'customers'
                // },
                {
                    Key: "loyal_points",
                    url: "/super/loyal_points",
                    istitle: false,
                    text: "Loyal Points",
                    Icon: User,
                    parentKey: 'customers'
                },
                {
                    Key: "wallet",
                    url: "/super/wallet",
                    istitle: false,
                    text: "Wallet",
                    Icon: User,
                    parentKey: 'customers'
                },

            ]
        },
        {
            key: "reports",
            // url: "/super/reports",
            isTitle: false,
            text: "Report",
            Icon: FileText,
            children: [
                {
                    key: "products",
                    url: "/super/reports/products",
                    istitle: false,
                    text: "Product",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "service",
                    url: "/super/reports/service",
                    istitle: false,
                    text: "Service",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "orders",
                    url: "/super/reports/orders",
                    istitle: false,
                    text: "Orders",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "invoices",
                    url: "/super/reports/invoice",
                    istitle: false,
                    text: "Invoices",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "shipping",
                    url: "/super/reports/shipping",
                    istitle: false,
                    text: "Shipping",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "items",
                    url: "/super/reports/items",
                    istitle: false,
                    text: "Items",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "service order",
                    url: "/super/reports/service_orders",
                    istitle: false,
                    text: "Service Orders",
                    Icon: Settings,
                    parentKey: 'reports'
                },
                {
                    key: "users_financial",
                    url: "/super/reports/users_financial",
                    istitle: false,
                    text: "Users Financial",
                    Icon: Settings,
                    parentKey: 'reports'
                },
            ]
        },
        {
            key: "settings",
            // url: "/super/dashboard",
            isTitle: false,
            text: "Settings",
            Icon: Settings,
            children: [
                {
                    key: "general_settings",
                    url: "/super/settings",
                    istitle: false,
                    text: "General Settings",
                    Icon: Settings,
                    parentKey: 'settings'
                },
            ]
        },
        {
            key: "logout",
            url: "/super/logout",
            istitle: false,
            text: "Logout",
            Icon: LogOut,
        }
    ]

    return (
        <>
            <Suspense>
                <SuperSideBar menuContent={menuContent} isMinimized={isSidebarMinimized} />
            </Suspense>
            <AdminTopNavBar toggleSidebar={toggleSidebar} isSidebarMinimized={isSidebarMinimized} homeLink={homeLink} />
            <Suspense>
                <div className="content-wrapper  p-0 " style={{ marginLeft: isSidebarMinimized ? "80px" : "250px", flex: "1" }}>
                    <section className="content">
                        {children}
                    </section>
                </div>
            </Suspense>
            <Suspense>
                <AdminFooter isSidebarMinimized={isSidebarMinimized} />
            </Suspense>
        </>
    );
}

export default SuperAdminHeader
