import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { CANCEL_DECLINE, CANCEL_REQUEST, ERROR, ORDERED, SUCCESS } from "../../include/constant"
// import { getCustomer } from "../../include/function"
import { formateDate, getCustomer, preventClick } from "../../include/function"
import "../../styles/Orders.css"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Modal from "../add-ons/Modal"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const CustomerOrders = ({ from }) => {
    const user = getCustomer()

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const [serviceOrders, setServiceOrders] = useState([])
    const [modalComponent, setModalComponent] = useState('')
    const [modalHeaderText, setMoadlHeaderText] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [activeTab, setActiveTab] = useState("orders");

    const getOrders = useCallback(() => {
        setLoading(true)
        api.get('/orders/?customerId=' + user.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setOrders(response.data.data)
            }
        }).catch(error => {
            setOrders([])
        }).finally(() => setLoading(false))
    }, [user.id])

    const getServiceOrders = useCallback(() => {
        setLoading(true)
        api.get('/service_orders/?customerId=' + user.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setServiceOrders(response.data.data)
            }
        }).catch(error => {
            setServiceOrders([])
        }).finally(() => setLoading(false))
    }, [user.id])

    useEffect(() => {
        getOrders()
        getServiceOrders()
    }, [getOrders, getServiceOrders])


    return (
        <div className={from === "orders" ? "fixed-top-bar" : ""}>
            <div className="position-relative">
            </div>
            {from === "orders" && <CustomerHeader enableSearch enableButtons />}
            <div className="container my-3">
                <h5 className="mb-3">Orders and Services</h5>
                {/* { Tabs } */}
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button
                            className={`nav-link fs-6 ${activeTab === "orders" ? "active" : ""}`}
                            onClick={() => setActiveTab("orders")}
                        >
                            Orders
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link fs-6 ${activeTab === "services" ? "active" : ""}`}
                            onClick={() => setActiveTab("services")}
                        >
                            Service Orders
                        </button>
                    </li>
                </ul>
                {/* {Tab Content } */}
                {activeTab === "orders" && (
                    <div className="my-3">
                        {loading ? (
                            <div className="py-5"><Spinner color="danger" /></div>
                        ) : (
                            <div className="row">
                                {orders?.length > 0 ? (
                                    orders?.map(order => (
                                        <OrderCard key={order.id} order={order} getOrders={getOrders}
                                            getServiceOrders={getServiceOrders} fromWhere='product' />
                                    ))
                                ) : (
                                    <Error mainText="No orders found!" />
                                )}
                            </div>
                        )}
                    </div>
                )}

                {activeTab === "services" && (
                    <div className="my-3">
                        {loading ? (
                            <div className="py-5"><Spinner color="danger" /></div>
                        ) : (
                            <div className="row">
                                {serviceOrders?.length > 0 ? (
                                    serviceOrders?.map(order => (
                                        <OrderCard key={order.id} order={order} getOrders={getOrders}
                                            getServiceOrders={getServiceOrders} fromWhere='service' />
                                    ))
                                ) : (
                                    <Error mainText="No service orders found!" />
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

const Cancel = ({ orderItemIds, getOrderItems, setShowModal }) => {
    const toast = useNotification();
    const [reason, setReason] = useState('');
    const [postLoading, setPostLoading] = useState(false);

    const cancelItems = async () => {
        const data = {
            userComment: String(reason).trim(),
            status: CANCEL_REQUEST,
        };

        setPostLoading(true);

        try {
            const cancelPromises = orderItemIds.map(id =>
                api.put(`/orders/?id=${id}&flag=changestatus`, { ...data, orderItemId: id }, customerReqConfig())
            );

            const responses = await Promise.all(cancelPromises);

            responses.forEach(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: `Order item ${response.data.id} cancelled successfully` });
                }
            });

            getOrderItems(); // Refresh the list
            setShowModal(false);
        } catch (error) {
            toast({
                type: ERROR,
                message: error.response?.data?.message || error.message,
            });
        } finally {
            setPostLoading(false);
        }
    };

    return (
        <div className="w-100">
            <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
            <textarea
                name="reason"
                id="reason"
                cols="30"
                rows="5"
                className="form-control mb-3"
                placeholder="Reason"
                style={{ resize: 'none' }}
                onChange={e => setReason(e.target.value)}
                value={reason}
            />
            <button
                className={`btn ${postLoading || !reason.trim() ? 'btn-secondary' : 'btn-danger'}`}
                disabled={postLoading || !reason.trim()}
                onClick={e => preventClick(e, cancelItems)}
            >
                {postLoading ? 'Cancelling...' : 'Cancel'}
            </button>
        </div>
    );
};


const OrderCard = ({ order, getOrders, getServiceOrders, fromWhere }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalComponent, setModalComponent] = useState(null);

    const handleShowCancelModal = () => {
        setModalComponent(
            <Cancel
                orderItemIds={order.orderItems.map(item => item.id)}
                getOrderItems={() => {
                    getOrders();
                    getServiceOrders();
                }}
                setShowModal={setShowModal}
            />
        );
        setShowModal(true);
    };

    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <h6>Orders Info</h6>
                        <p className="mb-0 text-muted">Placed on {formateDate(order.createTimestamp)}</p>
                        {fromWhere === 'product' &&
                            order.orderItems.some(item => item.status === CANCEL_DECLINE) && (
                                <p className="highlight-comment">
                                    {[
                                        ...new Set(
                                            order.orderItems
                                                .filter(item => item.status === CANCEL_DECLINE)
                                                .map(item => item.adminComment)
                                        )
                                    ].join(', ')}
                                </p>
                            )
                        }
                    </div>
                    {/* Render Cancel button only for product orders */}
                    {fromWhere === 'product' &&
                        order.orderItems.some(item => item.status === ORDERED) && (
                            <button
                                className="btn btn-danger btn-sm text-light"
                                onClick={e => preventClick(e, handleShowCancelModal)}
                            >
                                Cancel
                            </button>
                        )}
                </div>
                <div className="card-body">
                    {order.orderItems.map(item => (
                        <OrderItem key={item.id} item={item} fromWhere={fromWhere} />
                    ))}
                </div>
                <div className="card-footer">
                    <strong>Total Price: &#8377;{order.totalAmount}</strong>
                </div>
            </div>
            {/* Ensures modal renders properly */}
            {showModal && (
                <Modal
                    component={modalComponent}
                    handleClose={setShowModal}
                    headerText={<h5>Cancel Order</h5>}
                    zIndex={1050}
                />
            )}
        </div>
    );
};

const OrderItem = ({ item, fromWhere }) => {
    return (
        <Link to={fromWhere === 'service' ? "/service-order-details/" + item.id : "/order-details/" + item.id} className="d-block p-2 border-bottom">
            <div className="d-flex align-items-center">
                <img
                    className="me-3"
                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                    src={item.mainImage && baseURL + '/' + item.mainImage}
                    alt={item.productName}
                />
                <div className="flex-grow-1" style={{ overflow: 'hidden' }}>
                    <h6
                        className="mb-1 text-truncate"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {item.productName}
                    </h6>
                    <span className="text-muted small">
                        &#8377;{fromWhere === 'service'
                            ? Math.ceil(item.amount)
                            : Math.ceil(item.amount + (item.deliveryCharge))
                        }

                    </span>
                </div>
                <div className="text-end">
                    <span className={`badge  bg-success text-capitalize`}>
                        {item.status}
                    </span>
                </div>
            </div>
        </Link>
    )
}


export default CustomerOrders