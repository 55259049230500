import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../include/api";

const CustomerResetPassword = () => {
    const { customerId } = useParams(); // Retrieve customerId from the URL parameters
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage("");

        if (password !== confirmPassword) {
            setMessage("Passwords do not match.");
            setLoading(false);
            return;
        }

        try {
            const response = await api.put("customer/resetPassword/?id=" + customerId, {
                password,
            });

            if (response.status === 200 && response.data.success) {
                setMessage("Password reset successfully!");
            } else {
                setMessage(response.data.message || "Error resetting password.");
            }
        } catch (error) {
            setMessage("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center bg-primary">
            <div className="card p-4 shadow" style={{ maxWidth: '480px', width: '100%' }}>
                <div className="container mt-5">
                    {/* <div className="card shadow-sm p-4"> */}
                    <h3 className="text-center mb-4">Reset Password</h3>
                    {message && (
                        <div
                            className={`alert ${message.includes("successfull") ? "alert-success" : "alert-danger"
                                }`}
                        >
                            {message}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                New Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                id="confirmPassword"
                                className="form-control"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100 my-2" disabled={loading}>
                            {loading ? "Submitting..." : "Reset Password"}
                        </button>
                    </form>
                    {/* </div> */}
                </div>
            </div>
        </div >
    );
};

export default CustomerResetPassword;