import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig, superAdminReqConfig } from "../../include/api"
import { APPROVE, CANCELLED, CANCEL_DECLINE, CANCEL_REQUEST, COMPLETED, CONFIRMED, DELIVERED, ERROR, ORDERED, PROCESSED, RETURN_REQUEST, SUCCESS } from "../../include/constant"
import { formateDate, preventClick } from "../../include/function"
import "../../styles/ProgressBar.css"
import { useNotification } from "../../Toast/ToastProvider"
import Modal from "../add-ons/Modal"
import Spinner from "../add-ons/Spinner"
import YesOrNo from "../add-ons/YesOrNo"
import SuperAdminHeader from "./Header"

const SuperServiceOrderDetails = () => {
    const toast = useNotification()
    const { id } = useParams()
    // const user = getCustomer()
    const { push } = useNavigate()

    const [showModal, setShowModal] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(true)
    const [modalComponent, setModalComponent] = useState('')
    const [modalHeaderText, setMoadlHeaderText] = useState('')
    const [apiConfig, setApiConfig] = useState({})
    const [orderItem, setOrderItem] = useState({})
    const [approveYesOrNo, setApproveYesOrNo] = useState(false)
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const [putLoading, setPutLoading] = useState(false)
    const [processedModal, setProcessedModal] = useState(false)
    const [completedModal, setCompletedModal] = useState(false)
    const [completedAt, setCompletedAt] = useState('')
    const [confirmedModal, setConfirmedModal] = useState(false)
    const [status, setStatus] = useState('')
    const [reason, setReason] = useState('')

    const getApiConfig = () => {
        api.get('/config/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setApiConfig(response.data.data[0])
            }
        }).catch(error => { })
    }

    const getServiceOrderItem = useCallback((afterGet) => {
        setFetchLoading(true)
        api.get('/service_orders/?orderItemId=' + id, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setOrderItem(response.data.data[0])
                if (afterGet) afterGet()
            }
        }).catch(error => {
            push('/profile/orders')
        }).finally(() => {
            setFetchLoading(false)
        })
    }, [id, push])

    const handleApprove = () => {
        setStatus(APPROVE)
        setApproveYesOrNo(true)
    }

    const handleDecline = () => {
        setStatus(CANCEL_DECLINE)
        setShowDeclineModal(true)
    }

    const handleConfirmed = () => {
        setStatus(CONFIRMED)
        setConfirmedModal(true)
    }

    const handleProcessed = () => {
        setStatus(PROCESSED)
        setProcessedModal(true)
    }

    const handleCompleted = () => {
        setStatus(COMPLETED)
        setCompletedModal(true)
    }

    const setDefault = () => {
        setCompletedModal(false)
        setConfirmedModal(false)
        setProcessedModal(false)
        setApproveYesOrNo(false)
        setShowDeclineModal(false)
        setReason('')
    }

    const handleApproveYes = () => approveOrDecline()
    const handleYes = () => changeStatus()

    const approveOrDecline = () => {
        const data = {
            status: String(status).trim(),
            adminComment: String(reason).trim()
        }

        setPutLoading(true)
        api.put('/service_orders/?id=' + id + '&flag=approveOrDecline', data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                getServiceOrderItem(() => {
                    setDefault()
                    toast({ type: SUCCESS, message: response.data.message })
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPutLoading(false))
    }

    const changeStatus = () => {
        const data = {
            status: String(status).trim(),
            completedAt: String(completedAt).trim() !== '' ? formateDate(completedAt) : null,
        }

        setPutLoading(true)
        api.put('/service_orders/?id=' + id + '&flag=changestatus', data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                getServiceOrderItem(() => {
                    setDefault()
                    toast({ type: SUCCESS, message: response.data.message })
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPutLoading(false))
    }

    const handleShowRateAndReviewModal = () => {
        setMoadlHeaderText(<>Rate &amp; Review Product</>)
        setModalComponent(<RateAndReview
            customerId={''}
            productId={orderItem.productId}
        />)
        setShowModal(true)
    }

    const handleShowCancelModal = () => {
        setMoadlHeaderText(<>Cancel Item</>)
        setModalComponent(<Cancel
            getServiceOrderItem={getServiceOrderItem}
            orderItemId={orderItem.id}
            setShowModal={setShowModal}
        />)
        setShowModal(true)
    }

    const handleShowReturnModal = () => {
        setMoadlHeaderText(<>Return Item</>)
        setModalComponent(<Return
            getServiceOrderItem={getServiceOrderItem}
            orderItemId={orderItem.id}
            setShowModal={setShowModal}
        />)
        setShowModal(true)
    }

    useEffect(() => {
        getApiConfig()
    }, [])

    useEffect(() => {
        getServiceOrderItem()
    }, [getServiceOrderItem])

    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <div className="position-relative">
                {showModal && <Modal
                    component={modalComponent}
                    handleClose={setShowModal}
                    headerText={modalHeaderText}
                    zIndex={1050}
                />}
            </div>
            {processedModal
                && <YesOrNo
                    zIndex={1040}
                    handleYes={handleYes}
                    handleNo={() => setProcessedModal(false)}
                    headerText="Processed?"
                    yesBtnText="Yes"
                    loading={putLoading}
                />}
            {confirmedModal
                && <YesOrNo
                    zIndex={1040}
                    handleYes={handleYes}
                    handleNo={() => setConfirmedModal(false)}
                    headerText="Confirmed?"
                    yesBtnText="Yes"
                    loading={putLoading}
                />}
            {completedModal && <Modal
                zIndex={1040}
                component={<Delivered
                    changeStatus={changeStatus}
                    deliveredAt={completedAt}
                    putLoading={putLoading}
                    setDeliveredAt={setCompletedAt}
                />}
                handleClose={() => setCompletedModal(false)}
                headerText="Completed?"
            />}
            {approveYesOrNo && <YesOrNo
                zIndex={1040}
                handleYes={handleApproveYes}
                handleNo={() => setApproveYesOrNo(false)}
                headerText="Are you sure want to Approve?"
                yesBtnText="Yes"
                loading={putLoading}
            />}

            {showDeclineModal && <Modal
                zIndex={1040}
                component={<Decline
                    approveOrDecline={approveOrDecline}
                    reason={reason}
                    setReason={setReason}
                    putLoading={putLoading}
                />}
                handleClose={() => setShowDeclineModal(false)}
                headerText="Are you sure want to Decline?"
            />}
            {/* <CustomerHeader enableButtons /> */}
            {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> : <div className="container my-4">
                <div className="row">
                    <div className="col-12 col-lg-6 mb-4">
                        <div className="border py-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="fit-contain w-100 hover-scale" src={orderItem.mainImage && baseURL + '/' + orderItem.mainImage} alt="" style={{ maxHeight: '200px' }} />
                                    </div>
                                    <div className="col-12">
                                        <h6 className="my-2">{orderItem.productName}</h6>
                                        <h6 className="fw-bold">&#8377;{Math.ceil(orderItem.amount)}</h6>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <i className="fas fa-circle text-success blink me-2" style={{ fontSize: '.7rem' }} />
                                        <span className="h6 m-0 fw-bold text-capitalize">{orderItem.status}</span>
                                    </div>
                                    {(orderItem.status === RETURN_REQUEST || orderItem.status === CANCEL_REQUEST) && <><hr />
                                        <div className="col-12">
                                            <span className="small">{orderItem.status === CANCEL_REQUEST ? 'CANCEL REQUEST REASON : ' : ''}{orderItem.userComment}</span>
                                        </div></>}
                                    <div className="col-12 mt-3">
                                        {orderItem.status === CANCEL_REQUEST && <><button className="btn btn-success me-3" onClick={e => preventClick(e, handleApprove)}>Approve</button>
                                            <button className="btn btn-danger me-3" onClick={e => preventClick(e, handleDecline)}>Decline</button></>}


                                        {(orderItem.status === ORDERED || orderItem.status === CANCEL_DECLINE) && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleConfirmed)}>Confirmed?</button>}

                                        {orderItem.status === CONFIRMED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleProcessed)}>Processed?</button>}

                                        {orderItem.status === PROCESSED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleCompleted)}>Completed?</button>}


                                    </div>
                                    {orderItem.status === DELIVERED && orderItem.adminComment && <><hr />
                                        <div className="col-12">
                                            <span className="small">{orderItem.adminComment}</span>
                                        </div></>}
                                    {/* <div className="col-12 mt-3">
                                        {orderItem.status === ORDERED && <button className="btn btn-danger me-3" onClick={e => preventClick(e, handleShowCancelModal)}>Cancel</button>}
                                        {orderItem.status === DELIVERED && !isEmptyObj(apiConfig) && isNum(Number(apiConfig.returnDays)) && <button className="btn btn-primary me-3" onClick={e => preventClick(e, handleShowReturnModal)}>Return</button>}
                                        {orderItem.status === DELIVERED && <button className="btn btn-primary me-3" onClick={e => preventClick(e, handleShowRateAndReviewModal)}>Rate and Review</button>}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-4">
                        <div className="border p-3">
                            <h6 className="text-dark fw-bold">Delivery Details</h6>
                            <span className="small text-dark fw-bold me-3">{orderItem.name}</span>
                            <span className=" border border-1 d-inline-block px-2 text-uppercase small  mb-2">{orderItem.addressType}</span>
                            <p className="small text-truncate">{orderItem.address}, {orderItem.locality}, {orderItem.city} - {orderItem.pin}, {orderItem.state}</p>
                            <p className="small text-dark fw-bold mb-1">Phone number</p>
                            <p className="small mb-1 text-truncate">
                                <i className="fas fa-fw fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                                <span>{orderItem.phoneNumber}</span>
                            </p>
                            {orderItem.alternatePhoneNumber && <p className="small mb-1 text-truncate">
                                <i className="fas fa-fw fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                                <span>{orderItem.alternatePhoneNumber}</span>
                            </p>}
                            <br />
                            {orderItem.emailId && <div className="w-50"><p className="small text-dark fw-bold mb-1">Email</p>
                                <p className="small mb-1 text-truncate">{orderItem.emailId}</p>
                            </div>}
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 mb-4">
                        <div className="border p-3">
                            <h6 className="text-dark fw-bold">Order Status</h6>
                            <h6 className="my-2 text-truncate fw-bold text-capitalize">{ORDERED}</h6>
                            <OrderProgressBar orderItem={orderItem} />
                            {orderItem.cancelledAt ? (
                                <h6 className="mb-4 text-truncate small text-danger">This order has been cancelled.</h6>
                            ) : (
                                <h6 className="mb-4 text-truncate small">Your item is {orderItem.deliveredAt ? 'delivered' : 'in progress'}.</h6>
                            )}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    </SuperAdminHeader>


}

const Decline = ({ approveOrDecline, reason, putLoading, setReason }) => {
    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(putLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Decline</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, approveOrDecline)}>Decline</button>}
    </div>
}

const Delivered = ({ deliveredAt, setDeliveredAt, putLoading, changeStatus }) => {
    return <div className="w-100">
        <label htmlFor="deliveredAt">Completed At <span className="text-danger">&#42;</span></label>
        <input type="datetime-local" name="deliveredAt" id="deliveredAt" className="form-control form-control-lg my-2" onChange={e => setDeliveredAt(e.target.value)} value={deliveredAt} />
        {(putLoading || String(deliveredAt).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Continue</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, changeStatus)}>Continue</button>}
    </div>
}

const RateAndReview = ({ productId, customerId }) => {
    const toast = useNotification()

    const [rating, setRating] = useState('')
    const [review, setReview] = useState('')
    const [fetchLoading, setFetchLoading] = useState(true)
    const [postLoading, setPostLoading] = useState(false)

    const getRatingAndReview = useCallback(() => {
        setFetchLoading(true)
        api.get('/rating_and_review/?productId=' + productId + '&customerId=' + customerId, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setReview(response.data.data[0].review)
                setRating(response.data.data[0].rating)
            }
        }).catch(error => { }).finally(() => setFetchLoading(false))
    }, [customerId, productId])

    const addRatingAndReview = (e) => {
        e.preventDefault()

        const data = {
            customerId: customerId,
            productId: productId,
            rating: rating,
            review: review,
        }

        setPostLoading(true)
        api.post('/rating_and_review/', data, customerReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostLoading(false))
    }

    useEffect(() => {
        getRatingAndReview()
    }, [getRatingAndReview])

    return fetchLoading ? <div className="p-5"><Spinner color="danger" /></div> : <div className="w-100">
        <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
                {[...Array(5)].map((_, index) => {
                    return <span key={nanoid()} className={`me-1 0 cursor-pointer ${String(rating) >= String(index + 1) ? 'text-yellow' : 'text-light'}`} onClick={() => setRating(index + 1)} style={{ textShadow: '0px 0px 2px #000', fontSize: '3rem' }}>&#9733;</span>
                })}
            </div>
            {postLoading ?
                <button className="btn btn-primary text-end d-flex align-items-center justify-content-center" onClick={e => e.preventDefault()}>
                    <Spinner color="white" spinnerSize="sm" />
                    <span className="ms-3">Apply</span>
                </button> :
                <button className="btn btn-primary text-end" onClick={e => addRatingAndReview(e)}>Apply</button>}
        </div>
        <textarea name="review" id="review" cols="30" rows="10" className="form-control" placeholder="Review" style={{ resize: 'none' }} onChange={e => setReview(e.target.value)} value={review} />
    </div>
}

const Cancel = ({ orderItemId, getServiceOrderItem, setShowModal }) => {
    const toast = useNotification()
    const [reason, setReason] = useState('')
    const [postLoading, setPostLoading] = useState(false)

    const cancelItem = () => {
        const data = {
            userComment: String(reason).trim(),
            status: CANCEL_REQUEST,
            orderItemId: orderItemId,
        }

        setPostLoading(true)
        api.put('/service_orders/?id=' + data.orderItemId + '&flag=changestatus', data, customerReqConfig()).then(response => {
            if (response.status === 200) {
                getServiceOrderItem(() => {
                    toast({ type: SUCCESS, message: response.data.message })
                    setShowModal(false)
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostLoading(false))
    }

    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(postLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Cancel</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, cancelItem)}>Cancel</button>}
    </div>
}

const Return = ({ getServiceOrderItem, setShowModal, orderItemId }) => {
    const toast = useNotification()

    const [reason, setReason] = useState('')
    const [postLoading, setPostLoading] = useState(false)

    const returnItem = () => {

        const data = {
            userComment: String(reason).trim(),
            status: RETURN_REQUEST,
            orderItemId: orderItemId,
        }

        setPostLoading(true)
        api.put('/orders/?id=' + data.orderItemId + '&flag=changestatus', data, customerReqConfig()).then(response => {
            if (response.status === 200) {
                getServiceOrderItem(() => {
                    toast({ type: SUCCESS, message: response.data.message })
                    setShowModal(false)
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostLoading(false))
    }

    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(postLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Return</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, returnItem)}>Return</button>}
    </div>
}

const OrderProgressBar = ({ orderItem }) => {
    const steps = [
        { label: ORDERED, date: orderItem.createTimestamp },
        { label: CONFIRMED, date: '' },
        { label: CANCELLED, date: orderItem.cancelledAt },
        { label: PROCESSED, date: '' },
        { label: COMPLETED, date: orderItem.completedAt },

    ];

    const completedSteps = steps.filter(step => step.date);

    return (
        <div className="progress-container">
            {steps
                .filter(step => step.date)
                .map((step, index) => (
                    <div
                        key={index}
                        className={`progress-step ${completedSteps.includes(step) ? 'completed' : ''}`}
                    >
                        <div className="step-label">
                            <h6 className="fw-bold text-capitalize">{step.label}</h6>
                        </div>
                        <div class="progress-circle">
                            <div className={`step-circle ${step.date ? 'completed' : ''}`}></div>
                        </div>
                        <div className="step-date">
                            {step.date && (
                                <h6 className="small">{formateDate(step.date)}</h6>
                            )}
                        </div>
                    </div>
                ))}
        </div>
    );
};


export default SuperServiceOrderDetails