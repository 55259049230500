import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import * as Feather from "react-feather"
import { Link, useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig, superAdminReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { discountPrice, getServiceCart, isEmptyObj, preventArrow, preventClick, preventWheel, setServiceCart } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import { useCart } from "../context/UserCartCountContext"
import SuperAdminHeader from "../super/Header"

const SuperAdminCart = () => {
    const { id } = useParams()
    const toast = useNotification()
    // const customer = getCustomer()
    const { fetchCartCount } = useCart(); // Get totalCartItems from context

    const [localServiceCart, setServiceLocalCart] = useState(getServiceCart())
    const [cartProducts, setCartProducts] = useState([])
    const [orderItems, setOrderItems] = useState([])
    const [fetchLoading, setFetchLoading] = useState(true)
    // const [checkout, setCheckout] = useState('')
    const [totalItemsPrice, setTotalItemsPrice] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalDeliveryCharge, setTotalDeliveryCharge] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false)
    const [showServiceOrProduct, setShowServiceOrProduct] = useState('product')
    const [savedForLaterData, setSavedForLaterData] = useState({})

    const [cart, setCart] = useState([])
    const [customer, setCustomer] = useState([])

    const fetchcart = () => {
        if (id) {
            api.get('/cart/?customerId=' + id, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    setCart(response.data.data)
                }
            }).catch(error => {
                setCart([])
            }).finally()
        }
        //  else if (!isEmptyObj(customer) && flag === 'customer') {
        //     api.get('/cart/?customerId=' + customer.id, customerReqConfig()).then(response => {
        //         if (response.status === 200) {
        //             setCart(response.data.data)
        //         }
        //     }).catch(error => {
        //         setCart([])
        //     }).finally()
        // }
        else {
            setCart([])
        }
    }

    useEffect(() => {

        let totAmt = parseFloat(totalItemsPrice) + parseFloat(totalDeliveryCharge) - parseFloat(totalDiscount)
        setTotalAmount(totAmt)

    }, [totalItemsPrice, totalDiscount, totalDeliveryCharge])

    useEffect(() => {
        if (id) {
            api.get(`/customer/?id=${id}`, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    setCustomer(response.data.data)
                }
            }).catch(error => {
                setCustomer([])
            })
        }
    }, [id])

    const getSavedItems = () => {
        setFetchLoading(true)
        api.get('/save_for_later/?customerId=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setSavedForLaterData(response.data.data)
            }
        }).catch(error => {
            setSavedForLaterData({})
        }).finally(() => setFetchLoading(false))
    }

    useEffect(() => {
        fetchcart()
        // getSavedItems()
    }, [])


    useEffect(() => {
        let idArray = cart && cart[0] ? cart[0].cartItems.map(cart => cart.productId) : []
        // let idArray = !isEmptyObj(customer)
        //     ? cart && cart[0] ? cart[0].cartItems.map(cart => cart.productId) : []
        //     : localCart.map(cart => cart.id)
        // let idArray = localCart.map(cart => cart.id)
        if (idArray.length > 0) {

            idArray = JSON.stringify(idArray)
            getCartProducts(idArray)
        }
    }, [cart])

    useEffect(() => {
        // if (!isEmptyObj(customer) || flag === 'super') {
        if (cartProducts.length > 0 && cart.length > 0 && cart[0].cartItems.length > 0) {
            const products = cartProducts.slice()
            for (let index = 0; index < cart[0].cartItems.length; index++) {
                const cartItem = cart[0].cartItems[index]
                const productIndex = products.findIndex(cp => String(cp.id) === String(cartItem.productId))
                if (productIndex >= 0) {
                    const qty = Number(cartItem.qty)
                    const price = Number(products[productIndex].price)
                    const discountValue = Number(products[productIndex].discountValue)
                    const discountMode = products[productIndex].discountMode
                    const amount = (discountValue === '' || Number(discountValue) === 0) ? qty * price : qty * discountPrice(price, discountValue, discountMode)
                    const product = {
                        ...products[productIndex],
                        qty: qty,
                        amount: amount,
                    }
                    products[productIndex] = product
                    setOrderItems(products)
                }
            }
        }
        // }

    }, [cart, cartProducts])

    // useEffect(() => {
    //     const activeOrderItems = orderItems.filter(oi => oi.status === 'active' && oi.shopStatus === 'active')
    //     const checkout = {
    //         billAmount: totalItemsPrice,
    //         totalDiscount: totalDiscount,
    //         totalDeliveryCharge: totalDeliveryCharge,
    //         totalAmount: totalAmount,
    //         totalItems: totalItems,
    //         orderItems: activeOrderItems
    //     }
    //     setCheckout(checkout)
    // }, [cart, orderItems, totalItemsPrice, totalAmount, totalDiscount, totalDeliveryCharge, totalItems])

    useEffect(() => {

        const activeOrderItems = orderItems.filter(oi => oi.status === 'active' && oi.shopStatus === 'active')
        const totalItemsPrice = cart[0]?.itemsPrice ? cart[0].itemsPrice : 0
        const totalDiscount = cart[0]?.totalDiscountValue ? cart[0].totalDiscountValue : 0
        const totalDeliveryCharge = cart[0]?.totalDeliveryCharge ? cart[0].totalDeliveryCharge : 0
        // let totalItemsPrice = activeOrderItems?.reduce((prev, curr) => {
        //     return prev + (curr.price ? parseFloat(curr.price) : 0)
        // }, 0)
        // let totalDiscount = activeOrderItems?.reduce((prev, curr) => {
        //     let productsDisValue = 0;

        //     if (curr.discountMode === 'percent') {
        //         // Calculating discount amount for given percent using price
        //         productsDisValue += (parseFloat(curr.discountValue) / 100) * parseFloat(curr.price);
        //     } else if (curr.discountMode === 'amount') {
        //         productsDisValue += parseFloat(curr.discountValue);
        //     }
        //     return prev + parseFloat(productsDisValue)
        // }, 0)
        // let totalDeliveryCharge = activeOrderItems?.reduce((prev, curr) => {
        //     return prev + (curr.price ? parseFloat(curr.deliveryCharge) : 0)
        // }, 0)
        const totalAmount = (totalItemsPrice + totalDeliveryCharge) - totalDiscount

        setTotalItemsPrice(totalItemsPrice)
        setTotalDiscount(totalDiscount)
        setTotalDeliveryCharge(totalDeliveryCharge)
        setTotalAmount(totalAmount)
        setTotalItems(activeOrderItems.length)

    }, [orderItems, cart])

    const getCartProducts = (idArray) => {
        setFetchLoading(true)
        api.get('/product/?idArray=' + idArray, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setCartProducts(response.data.data)
            }
        }).catch(error => {
            setCartProducts([])
        }).finally(() => setFetchLoading(false))
    }

    const handleUpdateCartBySuperAdmin = async () => {
        setPlaceOrderLoading(true)
        // await setToLocalStorage(CHECKOUT, checkout)
        const data = {
            customerId: id,
            itemsPrice: totalItemsPrice,
            totalPrice: totalAmount,
            totalDiscountValue: totalDiscount,
            discountMode: '',
            totalDeliveryCharge: totalDeliveryCharge,
            cartItems: [
                ...cart[0].cartItems,
            ]
        }
        //
        api.put('/cart/?id=' + cart[0].id, data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
                fetchcart()
                setPlaceOrderLoading(false)
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally()
        // setTimeout(() => {
        //     navigate('/checkout')
        // }, 1000)
    }
    console.log(cart);

    return <>
        <SuperAdminHeader >

            <div className='fixed-top-bar'>
                <div className='container'>
                    {/* <div className="d-flex justify-content-start"> */}
                    <h5 className="m-3">Cart</h5>
                    <div className="hstack gap-2 ms-3">
                        <div class="form-check">
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                PRODUCT
                            </label>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={showServiceOrProduct === 'product'} onClick={() => setShowServiceOrProduct('product')}>
                            </input>
                        </div>
                        <div class="form-check">
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                SERVICE
                            </label>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={showServiceOrProduct === 'service'} onClick={() => setShowServiceOrProduct('service')}>
                            </input>
                        </div>
                    </div>

                    {/* {cart[0]?.editable === 0 && flag === 'customer' && showServiceOrProduct === 'product' && <div className="text-end blinking-badge">
                    <span class="badge btn-lg bg-warning fs-6">SUPER EDITED</span>
                </div>} */}
                    {/* </div> */}
                    <div className="overflow-hidden p-3">
                        {showServiceOrProduct === 'product'
                            ?
                            <div className="row">
                                <div className="col-12 col-lg-6 my-2">
                                    <div className="border border-1 shadow-sm">
                                        {cart && cart[0]?.cartItems && cart[0]?.cartItems.length > 0 ? cart[0].cartItems
                                            .sort((a, b) => (b?.status === "active" ? 1 : 0) - (a.status === "active" ? 1 : 0))
                                            .sort((a, b) => (b?.shopStatus === "active" ? 1 : 0) - (a.shopStatus === "active" ? 1 : 0))
                                            .map(item => {
                                                let product = {}
                                                if (orderItems.length > 0) {
                                                    let index = orderItems.findIndex(cp => String(cp.id) === String(item.productId))
                                                    if (index >= 0) product = orderItems[index]
                                                }
                                                return <CartProducts
                                                    customer={customer}
                                                    getSavedItems={getSavedItems}
                                                    savedForLaterData={savedForLaterData}
                                                    setTotalItemsPrice={setTotalItemsPrice}
                                                    setTotalDiscount={setTotalDiscount}
                                                    setTotalDeliveryCharge={setTotalDeliveryCharge}
                                                    setTotalAmount={setTotalAmount}
                                                    setTotalItems={setTotalItems}
                                                    // flag={flag}
                                                    fetchcart={fetchcart}
                                                    fetchLoading={fetchLoading}
                                                    item={item}
                                                    localCart={cart}
                                                    product={product}
                                                    setLocalCart={setCart}
                                                    key={nanoid()}
                                                    fetchCartCount={fetchCartCount}
                                                />
                                            }) : <Error mainText={"Cart is empty"} secondaryText={''} />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 my-2">
                                    {cart && cart.length > 0 && cart[0] && cart[0]?.cartItems && cart[0]?.cartItems.length > 0 && < div className="border border-1 position-sticky shadow-sm">
                                        <div className=" px-3 py-2 border-bottom">
                                            <h6 className="text-muted m-0">PRICE DETAILS</h6>
                                        </div>
                                        <div className="p-3">
                                            <h6 className="d-flex justify-content-between">
                                                <span className="text-muted">Price ({totalItems} Items)</span>
                                                <input
                                                    type="number"
                                                    value={totalItemsPrice}
                                                    onChange={(e) => setTotalItemsPrice(e.target.value)}
                                                    className="form-control w-50"
                                                />
                                            </h6>
                                            <h6 className="d-flex justify-content-between text-success">
                                                <span>Discount</span>
                                                <input
                                                    type="number"
                                                    value={totalDiscount}
                                                    onChange={(e) => setTotalDiscount(e.target.value)}
                                                    className="form-control w-50"
                                                />
                                            </h6>
                                            <h6 className="d-flex justify-content-between">
                                                <span className="text-muted">Delivery Charge</span>
                                                {/* {(totalDeliveryCharge === '' || totalDeliveryCharge === 0 || totalDeliveryCharge === '0') ? (
                                            <span className="text-success text-uppercase">Free</span>
                                        ) :  */}
                                                {/* ( */}
                                                <input
                                                    type="number"
                                                    value={totalDeliveryCharge}
                                                    onChange={(e) => setTotalDeliveryCharge(e.target.value)}
                                                    className="form-control w-50"
                                                />
                                                {/* )} */}
                                            </h6>
                                            <hr />
                                            {/* <h6 className="d-flex justify-content-between fw-bold">
                                            <span>Total Amount</span>
                                            <input
                                                type="number"
                                                value={totalAmount}
                                                onChange={(e) => setTotalAmount(e.target.value)}
                                                className="form-control w-50"
                                            />
                                        </h6> */}
                                            <h6 className="d-flex justify-content-between  fw-bold">
                                                <span>Total Amount</span>
                                                <span>&#8377;{totalAmount}</span>
                                            </h6>
                                            <hr />
                                            <span className="text-success small">You will save ₹{totalDiscount} on this order</span>
                                            <div className="d-flex justify-content-end">
                                                {placeOrderLoading ? <button className="btn btn-lg" onClick={e => e.preventDefault()}>Update Cart</button> :
                                                    <button className="btn btn-primary btn-lg" onClick={e => preventClick(e, handleUpdateCartBySuperAdmin)}>Update Cart</button>}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            : (showServiceOrProduct === 'service'
                                // (!isEmptyObj(customer) && localServiceCart && localServiceCart[0]?.serviceCartItems.length > 0
                                ? localServiceCart[0]?.serviceCartItems.length > 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-12 col-lg-6 my-2">
                                                <div className="border border-1 shadow-sm">
                                                    {localServiceCart[0]?.serviceCartItems.sort((a, b) => (b?.status === "Active" ? 1 : 0) - (a.status === "Active" ? 1 : 0))?.map(service => {
                                                        // let product = {}
                                                        // if (orderItems.length > 0) {
                                                        //     let index = orderItems.findIndex(cp => String(cp.id) === String(item.productId))
                                                        //     if (index >= 0) product = orderItems[index]
                                                        // }
                                                        return <Services
                                                            // flag={flag}
                                                            // fetchcart={fetchcart}
                                                            // fetchLoading={fetchLoading}
                                                            // item={item}
                                                            setServiceCart={setServiceCart}
                                                            customer={customer}
                                                            localServiceCart={localServiceCart}
                                                            setLocalServiceCart={setServiceLocalCart}
                                                            service={service}
                                                            // localCart={cart}
                                                            // setLocalCart={setCart}
                                                            key={nanoid()}
                                                        />
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 my-2">
                                                < div className="border border-1 position-sticky shadow-sm">
                                                    <div className=" px-3 py-2 border-bottom">
                                                        <h6 className="text-muted m-0">PRICE DETAILS</h6>
                                                    </div>
                                                    <div className="p-3">
                                                        <h6 className="d-flex justify-content-between">
                                                            <span className="text-muted">Price ({localServiceCart[0]?.totalItems} Items)</span>
                                                            <span>&#8377;{localServiceCart[0]?.totalAmount}</span>
                                                        </h6>
                                                        {/* <h6 className="d-flex justify-content-between text-success">
                                                <span>Discount</span>
                                                <span>&#8377;{totalDiscount}</span>
                                            </h6> */}
                                                        {/* <h6 className="d-flex justify-content-between">
                                                <span className="text-muted">Delivery Charge</span>
                                                {(totalDeliveryCharge === '' || totalDeliveryCharge === 0 || totalDeliveryCharge === '0') ?
                                                    <span className="text-success text-uppercase">Free</span> :
                                                    <span>&#8377;{totalDeliveryCharge}</span>}
                                            </h6> */}
                                                        <hr />
                                                        <h6 className="d-flex justify-content-between  fw-bold">
                                                            <span>Total Amount</span>
                                                            <span>&#8377;{localServiceCart[0]?.totalAmount}</span>
                                                        </h6>
                                                        <hr />
                                                        {/* <span className="text-success small">You will save ₹{totalDiscount} on this order</span> */}
                                                        {/* {flag === 'customer' && <div className="d-flex justify-content-end">
                                                    {placeOrderLoading ? <button className="btn btn-lg" onClick={e => e.preventDefault()}>Place order</button> :
                                                        <button className="btn btn-primary btn-lg" onClick={e => preventClick(e,)}>Place order</button>}
                                                </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </> : <div>
                                        <div className="row">
                                            <div className="col-12 col-lg-6 my-2">
                                                <div className="border border-1 shadow-sm">
                                                    <Error mainText={"Your Cart is empty"}
                                                        secondaryText={''} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 my-2">
                                            <div className="border border-1 shadow-sm">
                                                <Error mainText={"Your Cart is empty"} secondaryText={''} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    </ >
}

const Services = ({ service, customer, localServiceCart, setLocalServiceCart, setServiceCart }) => {

    const handleCustomerRemoveService = () => {
        let cartIndex = localServiceCart.findIndex(cart => cart.customerId === customer.id);
        if (cartIndex !== -1) {
            let existingCart = localServiceCart[cartIndex];
            let filteredServicesItems = existingCart.serviceCartItems.filter(item => item.serviceId !== service.serviceId);
            existingCart.serviceCartItems = filteredServicesItems
            // Update totalItems and totalAmount
            // existingCart.totalItems -= service.qty;
            // existingCart.totalAmount -= service.qty * service.serviceCharge;
            existingCart.totalItems = filteredServicesItems?.reduce((prev, curr) => {
                return prev + curr.qty
            }, 0)
            existingCart.totalAmount = filteredServicesItems?.reduce((prev, curr) => {
                return prev + (curr.qty * curr.price)
            }, 0)

            // Update cart in the list
            let updatedCarts = [...localServiceCart];
            updatedCarts[cartIndex] = existingCart;
            setLocalServiceCart(updatedCarts);
            setServiceCart(updatedCarts); // local storage
        }
    };

    const handleCustomerIncreaseCount = () => {
        let cartIndex = localServiceCart.findIndex(cart => cart.customerId === customer.id);
        if (cartIndex !== -1) {
            let existingCart = localServiceCart[cartIndex];
            let itemIndex = existingCart.serviceCartItems.findIndex(item => item.serviceId === service.serviceId);

            if (itemIndex !== -1) {

                // Increase quantity
                existingCart.serviceCartItems[itemIndex].qty += 1;

                // Update totalItems and totalAmount
                existingCart.totalItems += 1;
                existingCart.totalAmount += parseFloat(service.price);

                // Update cart in the list
                let updatedCarts = [...localServiceCart];
                updatedCarts[cartIndex] = existingCart;
                setLocalServiceCart(updatedCarts);
                setServiceCart(updatedCarts); // local storage
            }
        }
    };

    const handleCustomerDecreaseCount = () => {
        let cartIndex = localServiceCart.findIndex(cart => cart.customerId === customer.id);
        if (cartIndex !== -1) {
            let existingCart = localServiceCart[cartIndex];
            let itemIndex = existingCart.serviceCartItems.findIndex(item => item.serviceId === service.serviceId);

            if (itemIndex !== -1 && existingCart.serviceCartItems[itemIndex].qty > 1) {
                // Decrease quantity
                existingCart.serviceCartItems[itemIndex].qty -= 1;

                // Update totalItems and totalAmount
                existingCart.totalItems -= 1;
                existingCart.totalAmount -= parseFloat(service.price);

                // Update cart in the list
                let updatedCarts = [...localServiceCart];
                updatedCarts[cartIndex] = existingCart;
                setLocalServiceCart(updatedCarts);
                setServiceCart(updatedCarts); // local storage
            }
        }
    };

    return <>
        <div className="container border-bottom py-3">
            <div className="row">
                <div className="col-12 col-sm-3">
                    <img className="w-100 fit-contain" src={baseURL + '/' + service.image} alt="" style={{ minHeight: '50px', maxHeight: '150px' }} />
                </div>
                <div className="col-12 col-sm-9">
                    {(service?.status?.toLowerCase() === 'active') ?
                        <>
                            <h6 className="my-2 text-truncate">{service.name}</h6>
                            <span className="text-success mx-2">&#8377;{Math.ceil(service.price)}</span>

                            {/* {flag === 'customer' && <div className="d-flex align-items-center mt-3 w-50">
                                {Number(service.qty) === 1
                                    ?
                                    <i className=" fas fa-minus-circle  shadow-sm rounded-circle me-2" />
                                    :
                                    <i onClick={handleCustomerDecreaseCount} className="text-primary fas fa-minus-circle  shadow-sm cursor-pointer rounded-circle me-2" />}
                                <input type="number" className="count-box p-0 text-center" onKeyDown={e => preventArrow(e)} onFocus={e => preventWheel(e)} value={service.qty} onChange={e => e.preventDefault()} />
                                <i onClick={handleCustomerIncreaseCount} className="fas fa-plus-circle  shadow-sm text-primary cursor-pointer rounded-circle ms-2" />
                                <button className="btn btn-danger px-3 ms-4" onClick={handleCustomerRemoveService}><i className="far fa-trash-alt  font-weight-normal" /></button>
                            </div>} */}
                        </>
                        : <div>
                            <div className="badge bg-danger text-uppercase">Service Unavailable</div>
                            {/* <button className="btn btn-danger px-3 ms-4" onClick={handleCustomerRemoveService}><i className="far fa-trash-alt  font-weight-normal" /></button> */}
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}

const CartProducts = ({ item, product, localCart, setLocalCart, fetchLoading, fetchcart, setTotalItemsPrice, setTotalDiscount, setTotalDeliveryCharge, setTotalAmount, setTotalItems, savedForLaterData, getSavedItems, customer, fetchCartCount }) => {

    const [price, setPrice] = useState(0)
    const toast = useNotification()
    let cart = localCart

    useEffect(() => {
        // if (!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0) {
        //     setPrice(item.price)
        // } else {
        //     setPrice(Math.ceil(discountPrice((item.price * item.qty), item.discountValue, item.discountMode)))
        // }
        setPrice(parseFloat(item.price))
    }, [])

    const handleCustomerIncreaseCount = (item) => {
        let cartItemIndex = cart[0].cartItems?.findIndex(c => String(c.productId) === String(item.productId))
        cart[0].cartItems[cartItemIndex].qty = Number(cart[0].cartItems[cartItemIndex].qty) + 1;

        let productsDisValue = 0;
        if (product.discountMode === 'percent') {
            //calculating dis amt for given percent using price
            productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
        } else if (product.discountMode === 'amount') {
            productsDisValue += parseFloat(product.discountValue)
        }

        let itemsPrice = Number(cart[0].itemsPrice) + parseFloat(product.price);
        let totalDiscountValue = (cart[0].totalDiscountValue ? parseFloat(cart[0].totalDiscountValue) : 0) + productsDisValue;
        // let totalDiscountValue = cart[0].totalDiscountValue ? parseFloat(cart[0].totalDiscountValue) : 0 + product.discountValue ? parseFloat(product.discountValue) : 0;
        let totalDeliveryCharge = (cart[0].totalDeliveryCharge ? parseFloat(cart[0].totalDeliveryCharge) : 0);

        const data = {
            customerId: cart[0].customerId,
            itemsPrice: itemsPrice,
            totalDiscountValue: totalDiscountValue,
            discountMode: product.discountMode,
            totalDeliveryCharge: totalDeliveryCharge,
            totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
            // totalPrice: cart[0].totalPrice ? parseFloat(cart[0].totalPrice) : 0 + product.price ? parseFloat(product.price) : 0,
            cartItems: [
                ...cart[0].cartItems,
            ]
        }
        //
        api.put('/cart/?id=' + cart[0].id, data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
                fetchcart()
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally()
    }

    const handleCustomerDecreaseCount = (item) => {
        let cartItemIndex = cart[0].cartItems.findIndex(c => String(c.productId) === String(item.productId))
        cart[0].cartItems[cartItemIndex].qty = Number(cart[0].cartItems[cartItemIndex].qty) === 1 ? Number(cart[0].cartItems[cartItemIndex].qty) : Number(cart[0].cartItems[cartItemIndex].qty) - 1;

        let productsDisValue = 0;
        if (product.discountMode === 'percent') {
            //calculating dis amt for given percent using price
            productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
        } else if (product.discountMode === 'amount') {
            productsDisValue += parseFloat(product.discountValue)
        }

        let itemsPrice = Number(cart[0].itemsPrice) - parseFloat(product.price);
        // let totalDiscountValue = Number(cart[0].cartItems[cartItemIndex].qty) > 1
        //     ?
        //     (cart[0].totalDiscountValue ? parseFloat(cart[0].totalDiscountValue) : 0) - (product.discountValue ? parseFloat(product.discountValue) : 0)
        //     : parseFloat(cart[0].totalDiscountValue);

        let totalDiscountValue = Number(cart[0].cartItems[cartItemIndex].qty) > 1
            ?
            (cart[0].totalDiscountValue ? parseFloat(cart[0].totalDiscountValue) : 0) - productsDisValue
            : parseFloat(cart[0].totalDiscountValue);

        let totalDeliveryCharge = (cart[0].totalDeliveryCharge ? parseFloat(cart[0].totalDeliveryCharge) : 0);
        const data = {
            customerId: cart[0].customerId,
            itemsPrice: itemsPrice,
            totalDiscountValue: totalDiscountValue,
            discountMode: cart[0].discountMode,
            totalDeliveryCharge: totalDeliveryCharge,
            totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
            // totalPrice: Number(cart[0].cartItems[cartItemIndex].qty) > 1
            //     ?
            //     (cart[0].totalPrice ? parseFloat(cart[0].totalPrice) : 0) - (product.price ? parseFloat(product.price) : 0)
            //     : parseFloat(cart[0].totalPrice),
            // totalDeliveryCharge: Number(cart[0].cartItems[cartItemIndex].qty) > 1
            //     ?
            //     (cart[0].totalDeliveryCharge ? parseFloat(cart[0].totalDeliveryCharge) : 0) - (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0)
            //     : parseFloat(cart[0].totalDeliveryCharge),
            cartItems: [
                ...cart[0].cartItems,
            ]
        }
        //
        api.put('/cart/?id=' + cart[0].id, data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
                fetchcart()
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally()
    }

    const handleCustomerRemoveItem = () => {
        let updatedCartItems = [
            ...cart[0].cartItems.filter(c => String(c.productId) !== String(item.productId)),
        ]

        // let itemsPrice = Number(cart[0].itemsPrice) - parseFloat(product.price);
        // let totalDiscountValue = (cart[0].totalDiscountValue ? parseFloat(cart[0].totalDiscountValue) : 0) - (product.discountValue ? parseFloat(product.discountValue) : 0);
        // let totalDeliveryCharge = (cart[0].totalDeliveryCharge ? parseFloat(cart[0].totalDeliveryCharge) : 0) - (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0);
        let itemsPrice = updatedCartItems?.reduce((prev, curr) => {
            return prev + (curr.price ? parseFloat(curr.price) : 0)
        }, 0)
        let totalDiscountValue = updatedCartItems?.reduce((prev, curr) => {
            let productsDisValue = 0;

            if (curr.discountMode === 'percent') {
                // Calculating discount amount for given percent using price
                productsDisValue += (parseFloat(curr.discountValue) / 100) * parseFloat(curr.price);
            } else if (curr.discountMode === 'amount') {
                productsDisValue += parseFloat(curr.discountValue);
            }
            return prev + parseFloat(productsDisValue)
        }, 0)
        let totalDeliveryCharge = updatedCartItems?.reduce((prev, curr) => {
            return prev + (curr.price ? parseFloat(curr.deliveryCharge) : 0)
        }, 0)
        if (updatedCartItems.length > 0) {
            let data = {
                customerId: cart[0].customerId,
                itemsPrice: itemsPrice,
                totalDiscountValue: totalDiscountValue,
                discountMode: cart[0].discountMode,
                totalDeliveryCharge: totalDeliveryCharge,
                totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                cartItems: updatedCartItems
            }

            //
            api.put('/cart/?id=' + cart[0].id, data, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message })
                    fetchcart()
                    fetchCartCount()
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally()
        } else {
            //if no items in cart
            // data = {
            //     customerId: cart.customerId,
            //     totalPrice: 0,
            //     totalDiscountValue: 0,
            //     discountMode: "",
            //     totalDeliveryCharge: 0,
            //     cartItems: updatedCartItems
            // }
            api.delete('/cart/?id=' + cart[0].id, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message })
                    fetchcart()
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally()
        }
    }

    const handlePriceChange = (e) => {
        console.log(e.target.value);
        setPrice(e.target.value)
        // let cartItemIndex = cart[0].cartItems?.findIndex(c => String(c.productId) === String(item.productId))
        // cart[0].cartItems[cartItemIndex].price = e.target.value;
        handleCartProductPriceUpdate(e)
    }

    const handleCartProductPriceUpdate = async (e) => {
        // let updatedCart = cart
        let cartItemIndex = cart[0].cartItems?.findIndex(c => String(c.productId) === String(item.productId))
        cart[0].cartItems[cartItemIndex].price = parseFloat(e.target.value);


        let itemsPrice = cart[0]?.cartItems.reduce((prev, curr) => {
            return prev + parseFloat(curr.price) * parseInt(curr.qty)
        }, 0);
        let totalDiscountValue = 0;
        cart[0]?.cartItems?.forEach(element => {
            if (element.discountMode === 'percent') {
                //calculating dis amt for given percent using price
                totalDiscountValue += parseFloat(element.discountValue) / 100 * parseFloat(element.price)
            } else if (element.discountMode === 'amount') {
                totalDiscountValue += parseFloat(element.discountValue)
            }
        });

        let totalDeliveryCharge = cart[0]?.cartItems.reduce((prev, curr) => {
            return prev + parseFloat(curr.deliveryCharge)
        }, 0);

        // const data = {
        //     customerId: cart[0].customerId,
        //     itemsPrice: itemsPrice,
        //     totalDiscountValue: totalDiscountValue,
        //     discountMode: product.discountMode,
        //     totalDeliveryCharge: totalDeliveryCharge,
        //     totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
        //     // totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
        //     // totalPrice: cart[0].totalPrice ? parseFloat(cart[0].totalPrice) : 0 + product.price ? parseFloat(product.price) : 0,
        //     cartItems: [
        //         ...cart[0].cartItems,
        //     ]
        // }
        // console.log(data);

        // setLocalCart(data)
        setTotalItemsPrice(itemsPrice)
        setTotalDiscount(totalDiscountValue)
        setTotalDeliveryCharge(totalDeliveryCharge)
        setTotalAmount(itemsPrice - totalDiscountValue + totalDeliveryCharge)
        setTotalItems(cart[0]?.cartItems.length)

        //
        // api.put('/cart/?id=' + cart[0].id, data, superAdminReqConfig()).then(response => {
        //     if (response.status === 200) {
        //         toast({ type: SUCCESS, message: response.data.message })
        //         fetchcart()
        //     }
        // }).catch(error => {
        //     error.response ?
        //         toast({ type: ERROR, message: error.response.data.message }) :
        //         toast({ type: ERROR, message: error.message })
        // }).finally()
    }

    const IsProductSaved = savedForLaterData?.products?.find(f => String(f.id) === String(product.id))

    const AddToSaveForLaterHandler = () => {
        if (!IsProductSaved) {

            const data = {
                customerId: customer.id,
                productId: product.id
            }

            api.post('/save_for_later/', data, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    getSavedItems()
                    toast({ type: SUCCESS, message: response.data.message })
                    // setOrderPlaced(true)
                    handleCustomerRemoveItem()
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally()
        } else {
            toast({ type: SUCCESS, message: 'Item already exists in saved list' })
        }

    }

    return fetchLoading ? <div className="p-5"><Spinner /></div> : <div className="container border-bottom py-3">
        <div className="row">
            <div className="col-12 col-sm-3">
                <img className="w-100 fit-contain" src={baseURL + '/' + product.mainImage} alt="" style={{ minHeight: '50px', maxHeight: '150px' }} />
            </div>
            <div className="col-12 col-sm-9">
                {(product.status === 'active' && product.shopStatus === 'active') ?
                    <>
                        <h6 className="my-2 text-truncate">{product.name}</h6>
                        {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0
                            ?
                            <h6 className="d-flex justify-content-between">
                                <input
                                    type="number"
                                    value={price}
                                    onChange={(e) => handlePriceChange(e)}
                                    className="form-control w-25"
                                />
                            </h6>
                            :
                            <h6 className="fw-bold">
                                {/* <input
                                    type="number"
                                    value={price}
                                    onChange={(e) => handlePriceChange(e)}
                                    className="form-control w-25"
                                /> */}
                                <span>&#8377;{Math.ceil(discountPrice((item.price * item.qty), item.discountValue, item.discountMode))}</span>
                                {/* <del className="text-muted mx-2">&#8377;{Math.ceil(product.price * product.qty)}</del> */}
                                <input
                                    type="number"
                                    value={price}
                                    onChange={(e) => handlePriceChange(e)}
                                    className="form-control w-25"
                                />
                                <span className="text-success">{product.discountLabel}</span>
                            </h6>}
                        <div className="d-flex align-items-center mt-3 w-50">
                            {Number(item.qty) === 1
                                ? <i className=" fas fa-minus-circle  shadow-sm rounded-circle me-2" />
                                :
                                <i onClick={e => handleCustomerDecreaseCount(item)} className="text-primary fas fa-minus-circle  shadow-sm cursor-pointer rounded-circle me-2" />
                            }
                            <input type="number" className="count-box p-0 text-center" onKeyDown={e => preventArrow(e)} onFocus={e => preventWheel(e)} value={item.qty} onChange={e => e.preventDefault()} />
                            {<i onClick={e => handleCustomerIncreaseCount(item)} className="fas fa-plus-circle  shadow-sm text-primary cursor-pointer rounded-circle ms-2" />}
                            {<button className="btn btn-danger px-3 ms-4" onClick={handleCustomerRemoveItem}><i className="far fa-trash-alt  font-weight-normal" /></button>}
                        </div>
                        <div className="hstack d-block my-1">
                            <label className="fs-6 me-2 ">Review</label>
                            <span>
                                <i className="me-1"><Feather.Star size={20} fill="#FFA048" stroke="#FFA048" /></i>
                                <i className="me-1"><Feather.Star size={20} fill="#FFA048" stroke="#FFA048" /></i>
                                <i className="me-1"><Feather.Star size={20} fill="#FFA048" stroke="#FFA048" /></i>
                                <i className="me-1"><Feather.Star size={20} fill="#FFA048" stroke="#FFA048" /></i>
                                <i className="me-1"><Feather.Star size={20} strokeWidth={1} /></i>
                            </span>
                        </div>
                        <div className="hstack mt-2">
                            <p><i><Feather.ArrowLeftCircle fill="#1266F1" size={17} stroke="white" /></i></p>
                            <p> 7 Days return available</p>
                        </div>
                        {/* {flag === 'customer' && <div className="m-2">
                            <i><Feather.Heart size={16} strokeWidth={1.5} /></i>
                            <Link to='#' className="ms-2 text-primary text-decoration-underline" onClick={AddToSaveForLaterHandler}>Save For Later
                            </Link>
                        </div>} */}
                    </> : <div>
                        {/* {flag === 'customer' && <div className="m-2">
                            <i><Feather.Heart size={16} strokeWidth={1.5} /></i>
                            <Link to='#' className="ms-2 text-primary text-decoration-underline" onClick={AddToSaveForLaterHandler}>Save For Later
                            </Link>
                        </div>} */}
                        <div className="badge bg-danger text-uppercase">Unavailable</div>
                        <button className="btn btn-danger px-3 ms-4" onClick={handleCustomerRemoveItem}><i className="far fa-trash-alt  font-weight-normal" /></button>
                    </div>}
            </div>
        </div>
    </div>
}

const Product = ({ product, toast, customer, fetchSavedItems, cartData, fetchcart, fetchCartCount }) => {
    const navigate = useNavigate()

    const isInCart = () => cartData && cartData[0] ? cartData[0].cartItems?.filter(c => String(c.productId) === String(product.id)).length > 0 : false

    const handleAddToCart = (e) => {
        if (!isEmptyObj(customer)) {
            if (cartData.length > 0 && !isInCart()) {
                //cart exist new item adding
                console.log('innn');
                let productsDisValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    productsDisValue += parseFloat(product.discountValue)
                }

                let itemsPrice = (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0);
                let totalDiscountValue = (cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0) + productsDisValue;
                let totalDeliveryCharge = (cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0);

                const data = {
                    customerId: cartData[0].customerId,
                    itemsPrice: itemsPrice,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: product.discountMode ? product.discountMode : cartData[0].discountMode,
                    totalDeliveryCharge: totalDeliveryCharge,
                    totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                    // totalPrice: (cartData[0].totalPrice ? parseFloat(cartData[0].totalPrice) : 0) + (product.price ? parseFloat(product.price) : 0) - (product.discountValue) + (product.deliveryCharge),
                    cartItems: [
                        ...cartData[0].cartItems,
                        {
                            productId: product.id,
                            qty: 1,
                            price: product.price,
                            discountMode: product.discountMode,
                            discountValue: product.discountValue,
                            deliveryCharge: product.deliveryCharge,
                        }

                    ]
                }
                //
                api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        fetchcart()
                        handleRemoveItemFromSavedlist()
                        fetchCartCount()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else if (cartData.length > 0 && isInCart()) {
                //cart exist, item also exist qty increase

                let productsDisValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    productsDisValue += parseFloat(product.discountValue)
                }

                let cartItemIndex = cartData[0].cartItems.findIndex(c => String(c.productId) === String(product.productId))
                cartData[0].cartItems[cartItemIndex].qty += 1;

                let itemsPrice = (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0);
                let totalDiscountValue = (cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0) + productsDisValue;
                let totalDeliveryCharge = (cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0);

                const data = {
                    customerId: cartData[0].customerId,
                    itemsPrice: itemsPrice,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: (product.discountMode ? product.discountMode : cartData[0].discountMode),
                    totalDeliveryCharge: totalDeliveryCharge,
                    totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                    cartItems: [
                        ...cartData[0].cartItems,
                    ]
                }
                //
                api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        fetchcart()
                        handleRemoveItemFromSavedlist()
                        fetchCartCount()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else if (cartData.length === 0 && !isInCart()) {
                //No cart exist new cart and item adding

                let totalDiscountValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    totalDiscountValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    totalDiscountValue += parseFloat(product.discountValue)
                }
                const data = {
                    customerId: customer ? customer.id : '0',
                    itemsPrice: product.price,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: product.discountMode,
                    totalDeliveryCharge: product.deliveryCharge,
                    totalPrice: parseFloat(product.price) - (product.discountValue ? parseFloat(product.discountValue) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0),
                    cartItems: [
                        {
                            productId: product.id,
                            qty: 1,
                            price: product.price,
                            discountMode: product.discountMode,
                            discountValue: product.discountValue,
                            deliveryCharge: product.deliveryCharge,
                        }
                    ]
                }
                //
                api.post('/cart/', data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        fetchcart()
                        handleRemoveItemFromSavedlist()
                        toast({ type: SUCCESS, message: response.data.message })
                        // setOrderPlaced(true)
                        fetchCartCount()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            }
        } else if (isEmptyObj(customer)) {
            navigate('/login')
        }

    }

    const handleRemoveItemFromSavedlist = () => {
        if (customer.id && product.id) {
            api.delete(`/save_for_later/?customerId=${customer.id}&productId=${product.id}`, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    fetchSavedItems()
                    toast({ type: SUCCESS, message: response.data.message })
                    // setOrderPlaced(true)
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            })
        }
    }

    return <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
        <div className="text-center d-block p-3 border my-2 text-dark">
            {/* <div className="text-end">
                <i><Feather.Heart fill="#EA5757" stroke="#EA5757" /></i>
            </div> */}
            <Link to={"/product/" + product.id} >

                <img className="fit-contain img-nor hover-scale" src={product.mainImage && baseURL + '/' + product.mainImage} alt="" />
                <h6 className="my-2 text-truncate">{product.name}</h6>
                {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0
                    ?
                    <span>
                        <h6 className="fw-bold">&#8377;{Math.ceil(product.price)}</h6>
                        {/* <div className="hstack gap-1">
                            <button className="btn text-primary fw-bold add-to-cart">Add To Cart</button>
                            <button className="btn text-danger fw-bold add-to-cart my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </div> */}
                    </span>
                    :
                    <h6 className="fw-bold">
                        <span>&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                        <del className="text-muted mx-2">&#8377;{Math.ceil(product.price)}</del>
                        <span className="text-success">{product.discountLabel}</span>
                        {/* <div className="hstack gap-1">
                            <button className="btn text-primary fw-bold add-to-cart">Add To Cart</button>
                            <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </div> */}
                        <div>
                        </div>
                    </h6>
                }
            </Link>
            <div className="hstack gap-2">
                {cartData.length > 0
                    ? cartData[0]?.editable === 1
                        ? <><button className="btn text-primary fw-bold add-to-cart" onClick={handleAddToCart}>Add To Cart</button>
                            <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromSavedlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </>
                        : <button className="btn text-danger fw-bold  my-1" style={{ marginLeft: '65px' }} onClick={handleRemoveItemFromSavedlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                    : <><button className="btn text-primary fw-bold add-to-cart" onClick={handleAddToCart}>Add To Cart</button>
                        <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromSavedlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                    </>}
            </div>
        </div>
    </div>
}

export default SuperAdminCart