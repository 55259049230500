import { useEffect, useState, useRef } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import Flyout from "../common/Flyout";
import SuperAdminHeader from "./Header";
import FilterModal from "./FilterModal";

const Payment = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [paymentData, setPaymentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [invObj, setInvObj] = useState(null);
    const [cusObj, setCusObj] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({ fromDate: "", toDate: "", orderId: "", product: "", paymentMethod: "", status: "" });
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [selectQuery, setSelectQuery] = useState("");
    const [customer, setCustomer] = useState([]);
    const [settings, setSettings] = useState([]);
    const prevQueryRef = useRef();

    const handleSelectQueryChange = (query) => {
        setSelectQuery(query);
    };

    useEffect(() => {
        api.get('/settings/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setSettings(response.data.data)
            }
        }).catch(error => {
            setSettings([])
        }).finally()
    }, [])

    useEffect(() => {
        if (prevQueryRef.current !== selectQuery) {
            getCustomer(selectQuery);
            prevQueryRef.current = selectQuery;
        }
    }, [selectQuery]);

    const getCustomer = async (selectQuery) => {
        setFetchLoading(true);
        try {
            const response = await api.get(`/customer/search/?q=${selectQuery}`);
            console.log(response.data);  // Now you can access the data directly
            if (response.status === 200 && Array.isArray(response.data.data.customer)) {
                setCustomer(response.data.data.customer);
            }
        } catch (error) {
            console.error("Error fetching customer data:", error);
            setCustomer([]); // In case of an error, set the customer array to empty
        } finally {
            setFetchLoading(false);
        }
    };

    const dynamicFilters = [
        { key: "orderId", label: "Order ID", type: "text" },
        {
            key: "customerId", label: "Customer Name", type: "select", options: customer.map((c) => ({
                value: c.id,
                label: c.name
            }))
        },
        {
            key: "paymentStatus", label: "Payment Status", type: "select", options: [
                { value: "pending", label: "pending" },
                { value: "paid", label: "paid" }
            ]
        },
        {
            key: "paymentMethod", label: "Payment Method", type: "select", options: settings.filter(f => f.head.toLowerCase().includes('payment')).map((p) => ({
                value: p.key,
                label: p.key
            }))
        },
    ];


    const getPayment = (filterValues = {}) => {
        setFetchLoading(true);

        const todayDate = new Date().toISOString().split("T")[0];
        const yesterdayDate = new Date(Date.now() - 86400000).toISOString().split("T")[0];

        const queryParams = new URLSearchParams(filterValues).toString();

        const url = queryParams
            ? `/payment/?${queryParams}`
            : `/payment/?fromDate=${yesterdayDate}&toDate=${todayDate}&orderId=&customerId=&paymentStatus=&paymentMethod=`;

        api.get(url, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setPaymentData(response.data.data);
                setFilteredData(response.data.data);
            }
        }).catch(error => {
            setPaymentData([]);
            setFilteredData([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getPayment();
    }, []);

    const handleApplyFilters = (appliedFilters) => {
        setFilters(appliedFilters);
        setIsModalOpen(false);
        getPayment(appliedFilters);
    };

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = paymentData.filter((data) => {
            const paymentDate = new Date(data.date);
            return paymentDate >= from && paymentDate <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = paymentData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.customerId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.paymentStatus?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.paymentMode?.toLowerCase().includes(query.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    const toggleFlyout = (invoice, from) => {
        setIsFlyoutVisible(!isFlyoutVisible)
        if (from === 'invoice') {
            setInvObj(invoice)
            setCusObj(null)
        }
        else if (from === 'customer') {
            setCusObj(invoice)
            setInvObj(null)
        }
        else {
            setInvObj(null)
            setCusObj(null)
        }
    };

    return (
        <>
            <Flyout isVisible={isFlyoutVisible} onClose={() => toggleFlyout({})} orderId={invObj?.orderId} customerId={cusObj?.customerId}>
            </Flyout>
            {/* Filter Modal */}
            <FilterModal
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onApply={handleApplyFilters}
                dynamicFilters={dynamicFilters}
                initialFilters={filters}
                selectQuery={selectQuery}
                setSelectQuery={handleSelectQueryChange}
            />
            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Payment</h5>
                        <div className="card-body">

                            {/* Search Filter */}
                            <div className="card my-4 row">
                                <div className="search-section m-3 col-lg-9 col-md-8 col-xl-8">
                                    {/* Checkbox for toggling direct search */}
                                    <div className="form-check mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="directSearchCheckbox"
                                            checked={isCheckboxChecked}
                                            onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                                        />
                                        <label className="form-check-label" htmlFor="directSearchCheckbox">
                                            Direct Search (Filter Table Data)
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Order ID, Customer ID, Payment Status or Payment Method"
                                        value={searchQuery}
                                        onChange={isCheckboxChecked ? (e) => handleSearch(e.target.value) : undefined}
                                        onClick={!isCheckboxChecked ? () => setIsModalOpen(true) : undefined}
                                    />
                                </div>

                                {/* Payments Table */}
                                <div className="table-wrapper">
                                    {fetchLoading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Order Id</th>
                                                    <th>Customer</th>
                                                    <th>Payment Date</th>
                                                    <th>Payment Status</th>
                                                    <th>Payment Method</th>
                                                    <th>Amount</th>
                                                    <th>Currency</th>
                                                    <th>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                                                    filteredData.map((od, i) => (
                                                        <tr key={od.payment_id}>
                                                            <td>{i + 1}</td>
                                                            <td
                                                                className="cursor-pointer"
                                                                onClick={() => toggleFlyout(od, 'invoice')}
                                                            >
                                                                {od.orderId}
                                                            </td>
                                                            <td
                                                                className="cursor-pointer"
                                                                onClick={() => toggleFlyout(od, 'customer')}
                                                            >
                                                                {od.customerName}
                                                            </td>
                                                            <td>{od.date}</td>
                                                            <td>{od.paymentStatus}</td>
                                                            <td>{od.paymentMode}</td>
                                                            <td>{od.amount}</td>
                                                            <td>{od.currency}</td>
                                                            <td>
                                                                {od.address}
                                                                <br />
                                                                {od.city}
                                                                <br />
                                                                {od.state}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="10" style={{ textAlign: 'center' }}>
                                                            No data available
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default Payment;
