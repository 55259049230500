import React, { useEffect, useState, useRef } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import Flyout from "../common/Flyout";
import SuperAdminHeader from "./Header";
import FilterModal from "./FilterModal";


const Tracking = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [trackingData, setTrackingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [invObj, setInvObj] = useState(null);
    const [cusObj, setCusObj] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({ fromDate: "", toDate: "", orderId: "", description: "", customerId: ""});
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [selectQuery, setSelectQuery] = useState("");
    const prevQueryRef = useRef();

    const handleSelectQueryChange = (query) => {
        setSelectQuery(query);
    };

    useEffect(() => {
        if (prevQueryRef.current !== selectQuery) {
            getCustomer(selectQuery); 
            prevQueryRef.current = selectQuery;
        }
    }, [selectQuery]);
    
    const getCustomer = async (selectQuery) => {
        setFetchLoading(true);
        try {
            const response = await api.get(`/customer/search/?q=${selectQuery}`);
            console.log(response.data);  // Now you can access the data directly
            if (response.status === 200 && Array.isArray(response.data.data.customer)) {
                setCustomer(response.data.data.customer);
            }
        } catch (error) {
            console.error("Error fetching customer data:", error);
            setCustomer([]); // In case of an error, set the customer array to empty
        } finally {
            setFetchLoading(false);
        }
    };
    
    
    

    const dynamicFilters = [
        { key: "orderId", label: "Order ID", type: "text" },
        { key: "customerId", label: "Customer ID", type: "select" ,  options: customer.map((c) => ({
            value: c.id,  
            label: c.name 
        }))},
        { key: "description", label: "Description", type: "select", options: [
            { value: "ordered", label: "ordered" },
            { value: "booked", label: "booked" },
            { value: "invoice", label: "invoice" },
            { value: "cancelled", label: "cancelled" },
            { value: "shipped", label: "shipped" },
            { value: "delivered", label: "delivered" }
        ] },
    ];

    const getTracking = (filterValues = {}) => {
        setFetchLoading(true);

        const todayDate = new Date().toISOString().split("T")[0];
        const yesterdayDate = new Date(Date.now() - 86400000).toISOString().split("T")[0];

        const queryParams = new URLSearchParams(filterValues).toString();

        console.log(filterValues)

        const url = queryParams
            ? `/tracking/?${queryParams}`
            : `/tracking/?fromDate=${yesterdayDate}&toDate=${todayDate}&orderId=&customer=&description=`;

        api
            .get(url, superAdminReqConfig())
            .then((response) => {
                if (response.status === 200) {
                    setTrackingData(response.data.data);
                    setFilteredData(response.data.data);
                }
            })
            .catch(() => {
                setTrackingData([]);
                setFilteredData([]);
            })
            .finally(() => setFetchLoading(false));
    };


    useEffect(() => {
        getTracking();
    }, []);

    const handleApplyFilters = (appliedFilters) => {
        setFilters(appliedFilters);
        setIsModalOpen(false);
        getTracking(appliedFilters);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = trackingData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.customerName?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.description?.toLowerCase().includes(query.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    const toggleFlyout = (invoice, from) => {
        setIsFlyoutVisible(!isFlyoutVisible)
        if (from === 'invoice') {
            setInvObj(invoice)
            setCusObj(null)
        }
        else if (from === 'customer') {
            setCusObj(invoice)
            setInvObj(null)
        }
        else {
            setInvObj(null)
            setCusObj(null)
        }
    };

    return (
        <>
            <Flyout isVisible={isFlyoutVisible} onClose={() => toggleFlyout({})} orderId={invObj?.orderId} customerId={cusObj?.customerId}>
            </Flyout>
            {/* Filter Modal */}
            <FilterModal
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onApply={handleApplyFilters}
                dynamicFilters={dynamicFilters}
                initialFilters={filters}
                selectQuery={selectQuery}
                setSelectQuery={handleSelectQueryChange}
            />

            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Tracking</h5>
                        <div className="card-body">

                            <div className="card my-4 row">
                                <div className="search-section m-3 col-lg-9 col-md-8 col-xl-8">
                                    {/* Checkbox for toggling direct search */}
                                    <div className="form-check mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="directSearchCheckbox"
                                            checked={isCheckboxChecked}
                                            onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                                        />
                                        <label className="form-check-label" htmlFor="directSearchCheckbox">
                                            Direct Search (Filter Table Data)
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Order ID, Customer ID, or Description"
                                        value={searchQuery}
                                        onChange={isCheckboxChecked ? (e) => handleSearch(e.target.value) : undefined}
                                        onClick={!isCheckboxChecked ? () => setIsModalOpen(true) : undefined}
                                    />
                                </div>

                                {/* Tracking Data Table */}
                                <div className="table-wrapper">
                                    {fetchLoading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Order Id</th>
                                                    <th>Customer Name</th>
                                                    <th>Description</th>
                                                    <th>Last Updated</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                                                    filteredData.map((tracking, index) => {
                                                        const description = tracking.description || "No description available";
                                                        const descriptionLines = description.split('\n');

                                                        return (
                                                            <tr key={tracking.tracking_id}>
                                                                <td>{index + 1}</td>
                                                                <td
                                                                    className="cursor-pointer"
                                                                    onClick={() => { toggleFlyout(tracking, 'invoice') }}
                                                                >
                                                                    {tracking.orderId}
                                                                </td>
                                                                <td
                                                                    className="cursor-pointer"
                                                                    onClick={() => { toggleFlyout(tracking, 'customer') }}
                                                                >
                                                                    {tracking.customerName}
                                                                </td>
                                                                <td>
                                                                    {descriptionLines.map((line, lineIndex) => {
                                                                        if (line.includes("Tracking No:")) {
                                                                            const trackingNumber = line.replace("Tracking No: ", "").trim();
                                                                            return (
                                                                                <React.Fragment key={lineIndex}>
                                                                                    Tracking No:{" "}
                                                                                    {trackingNumber.startsWith('http') ? (
                                                                                        <a
                                                                                            href={trackingNumber}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            {trackingNumber}
                                                                                        </a>
                                                                                    ) : (
                                                                                        trackingNumber
                                                                                    )}
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <React.Fragment key={lineIndex}>
                                                                                    {line}
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    })}
                                                                </td>
                                                                <td>{tracking.date}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: 'center' }}>
                                                            No data available
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default Tracking;